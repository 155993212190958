
import React, { useCallback, useRef, useEffect, useState } from 'react';
import beautyApi from '../../api/beautyApi';
import MyRequest from '../beauty/ItemClientReq/MyRequest';
import ServiceOfMaster from '../beauty/ItemClientReq/ServiceOfMaster';

import s from '../beauty/ServiceMasters/ServicesCatalog/ServiceWrapper.module.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import ServicesLoadingBlock from '../beauty/ServiceMasters/ServicesCatalog/ServicesLoadingBlock';
import { useSelector, useDispatch } from 'react-redux';
import { onAddAllMasterServR, onPBCborderBottom, onPBCborderImage, onPBCcolor, onPBCfontWeight} from '../../redux/action';
import uniqid from 'uniqid';
import ClientRequest from './ClientRequest';

const ServicesOfMaster = (props) => {
    const dispatch = useDispatch();
    const AllServR = useSelector((state) => state.itemsAllMasterServR);
    const ServItemsR = useSelector((state) => state.ServItemsR);
    const beenScroll = useSelector((state) => state.scrollCatalog);
    const myId = useSelector((state) => state.accountBeauty.id);
    const urlPath = window.location.pathname;
    const masterAbout = useSelector((state) => state.masterAbout);
    const [data, setData] = useState(null);
    const ws = useRef(null);
    useEffect(() => {
  

      dispatch(onPBCborderBottom('4px solid transparent'));
      dispatch(onPBCborderImage('linear-gradient(to left, rgb(212, 50, 216) -0.07%, rgb(255, 96, 141) 51.49%, rgb(244, 165, 130) 99.93%) 1 / 1 / 0 stretch'));
      dispatch(onPBCcolor('#404246'));
      dispatch(onPBCfontWeight('800'));
      
    }, []);

    useEffect(() => {
      getServReq(ServItemsR, '',myId);
    }, [data]);

    var arrObjects = [];
    const makeUser = (cat_id, id,  cat_name, masterId, serives_add_id_names, services_add_id, services_id, services_name) => {
        return { 
          cat_id:cat_id, 
          id: id,
          cat_name: cat_name,
          masterId: masterId,
          serives_add_id_names: serives_add_id_names,
          services_add_id: services_add_id,
          services_id: services_id,
          services_name: services_name,
          // ...другие свойства
        };
      }

    const getServReq = async (RServiceEnd, RServicesValue, myId) => {
      
      const responseAllServR = await beautyApi.ServicesOfMaster(RServiceEnd, RServicesValue, props.masterID);

    for (let i = 0; i < responseAllServR.length; i++) {
      if (responseAllServR[i]['services_name'] !== 'Выбрать услугу') {
        let user = makeUser(
          responseAllServR[i]['cat_id'],
          responseAllServR[i]['id'],
          responseAllServR[i]['cat_name'],
          responseAllServR[i]['masterId'],
          responseAllServR[i]['serives_add_id_names'],
          responseAllServR[i]['services_add_id'],
          responseAllServR[i]['services_id'],
          responseAllServR[i]['services_name'],
          
        );
  
        arrObjects[i] = user;
      }

    }
      dispatch(onAddAllMasterServR(arrObjects.reverse()));
    };

    useEffect(() => {
      if (AllServR.length === 0) {
        //console.log('Первый ');
        getServReq(ServItemsR, '',myId);
      }

    }, []);

    // console.log(AllServR)
  
    return (
      
  <><div
            className={s.all_items}
            id="scrollableDiv"
            style={{ overflowY: 'scroll', overflowX: 'hidden' }}>

            {AllServR.length > 0 ? (
                <InfiniteScroll
                    style={{}}
                    dataLength={AllServR.length}
                    hasMore={true}
                    loader={<div style={{ width: '50%' }}>
                        {/* {Array.from(Array(4), (_, i) => (
                              <LoadingBlock key={uniqid()} />
                            ))} */}
                    </div>}
                    sscrollableTarget="scrollableDiv">
                    {AllServR.map((item) => (
                        <ServiceOfMaster key={uniqid()} {...item} />
                    ))}
                </InfiniteScroll>
            ) : (
              <><p style={{ marginBottom: '20px'}}>Мастер пока что не добавил ни одной услуги</p></>
                // Array.from(Array(4), (_) => <ServicesLoadingBlock key={uniqid()} />)
            )}
        </div><div className={s.footer}>

            </div></>
    );
  };

export default React.memo(ServicesOfMaster);
