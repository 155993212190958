import React, { useEffect, useRef, useState } from 'react';
import s from './req.module.css';
import { useHistory } from 'react-router-dom';
import uniqid from 'uniqid';
import { useDispatch } from 'react-redux';
import { onItemGo, onScrollCatalog } from '../../../redux/action';
import Popup from 'reactjs-popup';
import AcceptModulforClient from './AcceptModulforClient';
import Comm from '../../../asseets/Frame 348.png';
import Comm2 from '../../../asseets/Frame 349.png';
const MyRequest = ({ FIO, date, id, orderName, orderName2, orderName3, orderName4, orderName5, phome, price, status, time, comm, img, clientId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dropDownComm, setDropDownComm] = useState(false);
  const [dropDownReqAccept, setDropDownReqAccept] = useState(false);
  const options = { weekday: 'long',  month: 'long', day: 'numeric' };

  const day = new Date(date);

  const toItem = () => {
    dispatch(onItemGo(true));
    dispatch(onScrollCatalog(window.pageYOffset));
  };
  
  const ReqAccept = () => {
    setDropDownReqAccept(!dropDownReqAccept);
  }

  return (
    <div style={{marginBottom: '20px'}}>
    <div className={s.item_bshop} key={uniqid()} 
    >
          <div style = {{marginTop: '8px'}} className={s.item__bshop_image} onClick={() => ReqAccept()}>
              <img style = {{height: '60px', width: '60px'}}
                  src={img && img !== ''
                      ? 'https://bshop.kz/beauty/AvatarPhotos/' + img
                      : 'https://bshop.kz/beauty/AvatarPhotos/0.png'}
                  alt="logo" />

            <p className={s.item_client_req_fio}>{FIO}</p>
          </div>

          <div className={s.item_client_req_info}>
          <div style = {{marginBottom: '8px'}} className={s.item_client_req_info_name_id}>  
                  
                  <div style = {{width: '180px'}} className={s.item_client_req_info_date}>{orderName} <br/></div>
                  {orderName2 == '' ? null : (<div style = {{width: '180px'}} className={s.item_client_req_info_date}>{orderName2} <br/></div>)}
                  {orderName3 == '' ? null : (<div style = {{width: '180px'}} className={s.item_client_req_info_date}>{orderName3} <br/></div>)}
                  {orderName4 == '' ? null : (<div style = {{width: '180px'}} className={s.item_client_req_info_date}>{orderName4} <br/></div>)}
                  {orderName5 == '' ? null : (<div style = {{width: '180px'}} className={s.item_client_req_info_date}>{orderName5} <br/></div>)}

                  {/* <div className={s.item_client_req_info_time}>{time}</div> */}

                  
              </div>
              {/* <div style = {{display: 'inline'}} className={s.item_client_req_info_nameClient}>Категория: {status}</div>
              <div></div>
              <div style = {{display: 'inline'}} className={s.item_client_req_info_nameClient}>Дата: {day.toLocaleDateString("ru-ru", options)}</div>
              <div></div>
              <div style = {{display: 'inline'}} className={s.item_client_req_info_nameClient}>Время: {time}</div>
              <div className={s.item_client_req_info_comm_price}>
                  <div 
                  style = {{marginLeft: '15px'}} 
                  className={s.item_client_req_info_price}>Цена: {price} тнг</div>
              </div> */}

                <div style = {{display: 'block', fontSize: '12px', float: 'left', marginLeft: '10px', lineHeight: '18px', fontFamily: 'Gotham Pro Regular', textAlign: 'left'}} > Дата:<br/><br/> Время:<br/> Цена:</div>
                <div style = {{display: 'inline-block', marginBottom: '10px', textAlign: 'left'}} className={s.item_client_req_info_nameClient}>{day.toLocaleDateString("ru-ru", options)}<br/>{time == '' ? 'Не задано' : time}<br/><b>{price}</b> тг</div>
        {comm == '' ? <div  style = {{display: 'inline-block'}}> <img src={Comm2}/></div> : (
        <Popup trigger={ <div  style = {{display: 'inline-block'}}> <img src={Comm}/></div>} modal nested>
            {close => (
        <div style={{height: `216px`, marginTop: '210px'}} className={s.modal_comm}>
            <p><b>Комментарий клиента</b></p>
            <p><br/></p>
            <p>{comm}</p>
              <button onClick={close} style={{ textAlign: 'center', height: '32px', width: '152px', textDecoration: `none`, position: 'absolute', top: '370px', left: '70px'}} className={s.beauty_button_confirm}>Понятно</button>

        </div>
        )}
        </Popup>)}
          </div>
      </div>
                
        {/* <AcceptModulforClient orderName={orderName} date={date} id={id} phome={phome} price={price} time={time} clientId={clientId} /> */}
        </div>
  );
};

export default MyRequest;
