// /* eslint-disable react/jsx-pascal-case */
// import React from 'react';
// import './style/Registration_form_style.css';
// import Registration_text_field from './Registration_text_field';
// import { ToastContainer, toast } from 'react-toastify';
// import star from '../../../src/asseets/star.png'

// class Registartion_form_client extends React.Component {
//   state = {
//     FIO: '',
//     SalonName: '',
//     Adress: '',
//     Phone: '',
//     Email: '',

//     NameError: '',
//     PhoneError: '',
//     EmailError: '',
//   };

//   wave_error = () => {
//     if (this.state.FIO === '') {
//       toast('Ошибка! Пустое поле ФИО', {
//         backgroundColor: '#323131',
//         color: '#ffffff',
//       });
//     } else if (Boolean(this.state.NameError) === true) {
//       toast('Ошибка! Некорректно введенное поле ФИО', {
//         backgroundColor: '#323131',
//         color: '#ffffff',
//       });
//     } else if (this.state.Phone === '') {
//       toast('Ошибка! Пустое поле Телефона', {
//         backgroundColor: '#323131',
//         color: '#ffffff',
//       });
//     } else if (Boolean(this.state.PhoneError) === true) {
//       toast('Ошибка! Некорректно введенное поле Телефон', {
//         backgroundColor: '#323131',
//         color: '#ffffff',
//       });
//     } else if (this.state.Email === '') {
//       toast('Ошибка! Пустое поле Email', {
//         backgroundColor: '#323131',
//         color: '#ffffff',
//       });
//     } else if (Boolean(this.state.EmailError) === true) {
//       toast('Ошибка! Некорректно введенное поле Email', {
//         backgroundColor: '#323131',
//         color: '#ffffff',
//       });
//     }
//   };

//   wave_success = () =>
//     toast('Отлично! Вы успешно зарегистрировались!', {
//       backgroundColor: '#10df3d',
//       color: '#ffffff',
//     });

//   validateName = (name) => {
//     const regex = /[A-Za-zА-Яа-я]{2,}/;
//     return !regex.test(name);
//   };

//   validateEmail = (name) => {
//     const emregex = /\S+@\S+\.\S+/;
//     return !emregex.test(name);
//   };

//   validatePhone = (name) => {
//     var phone_len = name.length;
//     let isLetter = /[a-zа-яё]/i.test(name);
//     if (phone_len === 18 && isLetter === false) {
//       return false;
//     } else {
//       return true;
//     }
//   };

//   onNameBlur = () => {
//     const { FIO } = this.state;
//     const NameError = this.validateName(FIO);
//     console.log(NameError);
//     return this.setState({ NameError });
//   };

//   onPhoneBlur = () => {
//     const { Phone } = this.state;
//     const PhoneError = this.validatePhone(Phone);
//     console.log(PhoneError);
//     return this.setState({ PhoneError });
//   };

//   onEmailBlur = () => {
//     const { Email } = this.state;
//     const EmailError = this.validateEmail(Email);
//     console.log(EmailError);
//     return this.setState({ EmailError });
//   };
//   //_____________________________//_____________________________

//   //_____________________________//_____________________________
//   onFIOChange = (event) =>
//     this.setState({
//       FIO: event.target.value,
//     });

//   onPhoneChange = (event) => {
//     this.setState({
//       Phone: event.target.value,
//     });
//     var old = 0;

//     var curLen = event.target.value.length;

//     if (curLen < old) {
//       old--;
//       return;
//     }

//     if (curLen === 1) event.target.value = event.target.value = '+7 (';

//     if (curLen === 7) event.target.value = event.target.value + ')-';

//     if (curLen === 12) event.target.value = event.target.value + '-';

//     if (curLen === 15) event.target.value = event.target.value + '-';

//     if (curLen > 18) event.target.value = event.target.value.substring(0, 17);
//     old++;
//   };

//   onEmailChange = (event) => {
//     this.setState({
//       Email: event.target.value,
//     });
//   };

//   SendRegForm = async (e) => {
//     e.preventDefault();

//     if (
//       Boolean(this.state.NameError) === false &&
//       Boolean(this.state.PhoneError) === false &&
//       Boolean(this.state.EmailError) === false &&
//       this.state.FIO !== '' &&
//       this.state.Phone !== '' &&
//       this.state.Email !== ''
//     ) {
//       this.wave_success();
//       console.log('SendRegForm:');
//       const regdata = new FormData();
//       regdata.append('FIO', this.state.FIO);
//       regdata.append('Phone', this.state.Phone);
//       regdata.append('Email', this.state.Email);
//       regdata.append('status', '0');

//       let response = await fetch(`https://bikli.kz/apiBeauty/BRegistration_form2.php`, {
//         method: 'POST',
//         body: regdata,
//       });

//       let commits = await response.json(); // читаем ответ в формате JSON
//       console.log(commits.form);
//     } else {
//       this.wave_error();
//     }
//   };

//   //_____________________________//_____________________________

//   //_____________________________//_____________________________

//   render() {
//     const { NameError, PhoneError, EmailError } = this.state;
//     const urlPath = localStorage.getItem('urlPath');
//     urlPath === '/beauty/profile' ? window.location.reload() : console.info()

//     return (
//       <div className="reg_body">
//         <div style={{ marginTop: '120px' }} className="reg_wrapper">
//           <h1 className="reg_title" style={{ marginTop: '20px' }}>
//             Регистрация
//           </h1>

//           <div className="registration_form">
//           <p style={{ marginLeft: '-165px', display: 'inline' }}>Имя</p> <img style={{ marginBottom: '8px' }} src={star}></img>
//             <Registration_text_field
//               name="FIO"
//               onChange={this.onFIOChange}
//               onBlur={this.onNameBlur}
//               error={NameError}
//               // placeholder="Ф.И.О."
//               className="reg_placeholder"
//             />
//             <p style={{ marginLeft: '-135px', display: 'inline' }}>Телефон</p> <img style={{ marginBottom: '8px' }} src={star}></img>

//             <form name="registration" className="PhoneInputReset">

//               <Registration_text_field
//                 name="Phone"
//                 onChange={this.onPhoneChange}
//                 onBlur={this.onPhoneBlur}
//                 error={PhoneError}
//                 // placeholder="Телефон"
//                 className="reg_placeholder_phone"
//               />
//               <button type="reset" className="reg_reset_btn"></button>
//             </form>
//           <p style={{ marginLeft: '-155px', display: 'inline' }}>Email</p> <img style={{ marginBottom: '8px' }} src={star}></img>

//             <Registration_text_field
//               name="Email"
//               onChange={this.onEmailChange}
//               onBlur={this.onEmailBlur}
//               error={EmailError}
//               // placeholder="Email"
//               className="reg_placeholder"
//             />

//             <div className="inputGroup">
//               <button onClick={this.SendRegForm} className="reg_btn">
//                 Отправить
//               </button>
//               <ToastContainer position="top-center" theme='dark' limit={4}/>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default Registartion_form_client;


import React, { useRef, useState } from 'react';
import '../../pages/beauty/style/Registration_form_style.css';
import Registration_text_field from '../../pages/beauty/Registration_text_field';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Inputmask from 'inputmask';
import star from '../../../src/asseets/star.png'


const Registartion_form_client = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const inputP = useRef();

  const [UserName , setName] = useState('');
  const [Phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [phoneInput, setphoneInput] = useState(false);

  const urlPath = localStorage.getItem('urlPath');
  const emregex = /\S+@\S+\.\S+/;

  inputP.current && Inputmask({ mask: '+7 (999)-999-99-99' }).mask(inputP.current);

    // NEW
    const [verificationCode, setVerificationCode] = useState("");
    const [serverVerificationCode, setServerVerificationCode] = useState("");
    const [tab, setTab] = useState(0);
    // ************************


const onPhoneInput = () => {
    inputP.current.value = '';
    setphoneInput(true);
  };

const wave_error_phone = () => {
      toast('Ошибка! Некорректно введенное поле Телефон', {
        backgroundColor: '#323131',
        color: '#ffffff',
      });
  };
const wave_error_name = () => {
    toast('Ошибка! Некорректно введенное поле Имя', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
};
const wave_error_email= () => {
  toast('Ошибка! Некорректно введенное поле Почты', {
    backgroundColor: '#323131',
    color: '#ffffff',
  });
};
const wave_error_adress = () => {
  toast('Ошибка! Некорректно введенное поле Адрес', {
    backgroundColor: '#323131',
    color: '#ffffff',
  });
};

const wave_success = () =>
  toast('Отлично! Вы успешно зарегистрировались! Для входа используйте введенный номер телефона и введенный код (4 цифры)', {
          backgroundColor: '#10df3d',
          color: '#ffffff',
        });
    
const wave_yandex = () => 
toast('Похоже, что вы используете почту Яндекс, поэтому вам может прийти "пустое" сообщение. Для получения скрытой информации перейдите в свойства письма.', {
  backgroundColor: '#10df3d',
  color: '#ffffff',
});
    
const wave_mail_not_success  = () =>
toast('Ошибка! Не удалось отправить письмо на указанную почту', {
  backgroundColor: '#10df3d',
  color: '#ffffff',
});

const wave_repeat_not_success = () =>
toast('Ошибка! Клиент уже зарегистрирован!', {
  backgroundColor: '#323131',
  color: '#ffffff',
});

const undef_error = () =>
toast('Возникла ошибка на стороне сервера. Попробуйте позже', {
  backgroundColor: '#323131',
  color: '#ffffff',
});


const PhoneCode = async () => {

  if (phoneInput == true) {
    if (inputP.current.inputmask.undoValue !== undefined) {
      if (inputP.current.inputmask.undoValue[17] !== "_") {
        if (inputP.current.inputmask.undoValue !== "") {
          if (UserName !== "") {

              setTab(1);
              const phonedata = new FormData();
              let formatted_phone = Phone;
              formatted_phone = formatted_phone.replaceAll("-", "");
              formatted_phone = formatted_phone.replaceAll(" ", "");
              formatted_phone = formatted_phone.replaceAll("(", "");
              formatted_phone = formatted_phone.replaceAll(")", "");
              formatted_phone = formatted_phone.replace("+7", "8");
              phonedata.append("phone", formatted_phone);
              
              fetch(
                `https://beauty.bikli.kz/beautyApi/flashcall.php`,
                {
                  method: "POST",
                  body: phonedata,
                }
              )
              .then(response => response.json())
              .then(res => setServerVerificationCode(res.code));

          } else {
            wave_error_name();
          }
        } else {
          wave_error_phone();
          }
      } else {
        wave_error_phone();
        }
    } else {
      wave_error_phone();
      }
  } else {
    wave_error_phone();
  }


}

  const SendRegForm = async (e) => {
    e.preventDefault();

    if (phoneInput == true) {
      if (inputP.current.inputmask.undoValue !== undefined) {
        if (inputP.current.inputmask.undoValue[17] !== '_') {
          if (inputP.current.inputmask.undoValue !== '') {
          if (UserName  !==  '') {
  
                const regdata = new FormData();
  
                regdata.append('FIO', UserName );
                regdata.append('Phone', inputP.current.inputmask.undoValue);
                regdata.append('SalonName', "__Клиент__");
                regdata.append('status', 0);
                regdata.append("password", serverVerificationCode.substr(2,4));

                let response = await fetch(`https://bikli.kz/apiBeauty/BRegistration_form3.php`, {
                  method: 'POST',
                  body: regdata,
                });
              let commits = await response.json(); // читаем ответ в формате JSON
              const PHPresponse = commits[0].response;
              console.log(PHPresponse)

              PHPresponse == 'Успешно!' ? wave_success() : 
              PHPresponse == 'Клиент уже зарегистрирован!' ?  wave_repeat_not_success() : 
              PHPresponse == 'Ошибка отправки письма' ?  wave_success() :
              undef_error()

              if(email.indexOf('yandex') + 1) {
                wave_yandex();
              } 
          } else {
            wave_error_name();
          }
        } else {
          wave_error_phone();
        }
      } else {
        wave_error_phone();
      }
    } else {
      wave_error_phone();
    }
    } else {
      wave_error_phone();
    }

  };

  return (
      <div className="reg_body">
        <div style={{ marginTop: '40px' }} className="reg_wrapper">
          <h1 className="reg_title" style={{ marginTop: '90px' }}>
            Регистрация
          </h1>

        <div className="registration_form">
          
          <p style={{ marginLeft: '-165px', display: 'inline' }}>Имя</p> <img style={{ marginBottom: '8px' }} src={star}></img>          
          <Registration_text_field
            name="password"
            onChange={(e) =>setName(e.target.value)}
            error="none"
            // placeholder="Пароль"
            className="reg_placeholder"
          />

          <p style={{ marginLeft: '-135px', display: 'inline'  }}>Телефон</p>  <img style={{ marginBottom: '8px' }} src={star}></img>
          <div className="inputGroup" >
            <input ref={inputP} className = 'reg_placeholder' type="text" onChange={(e) => setPhone(e.target.value)} onClick={() => {onPhoneInput()}}/>
          </div>

              <div style={{ display: tab == 0 ? 'block' : 'none' }} className="inputGroup">
                <button
                  onClick={PhoneCode}
                  className="reg_btn"
                  style={{ marginBottom: 20 }}
                >
                  Получить код
                </button>

              </div>


              <div style={{ display: tab == 1 ? 'block' : 'none' }}>
              <p style={{ marginLeft: "25px", display: "inline" }}>
                Введите последние 4 цифр номера
              </p>
              

              <Registration_text_field
                name="passwordCode"
                onChange={(e) => setVerificationCode(e.target.value)}
                error="none"
                placeholder="Код"
                className="reg_placeholder"
                // label={"Код"}
              />

              <div className="inputGroup">
                <button
                  onClick={(e) => {
                    if (verificationCode == serverVerificationCode.substr(2,4)) {
                      SendRegForm(e);
                      console.log("success");
                    } else {
                      console.log("error");
                    }
                  }}
                  className="reg_btn"
                  style={{ marginBottom: 20 }}
                >
                  Продолжить
                </button>
              </div>
              </div>

            <button
              className="reg_abs"
              style={{ textAlign: 'center', marginBottom: 20 }}
              onClick={() => history.goBack()}>
              назад
            </button>

            <div><img  style={{display: 'inline' }} src={star}></img><p  style={{ marginLeft: '10px', display: 'inline' }}> поля обязательные к заполнению</p></div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ToastContainer
                    position="top-center"
                    theme="dark"
                    limit={3}
                  />
                </div>
        </div>
      </div>
    </div>
  );
};

export default Registartion_form_client;
