import React, { useEffect, useRef, useState } from 'react';
import s from './req.module.css';
import { useHistory } from 'react-router-dom';
import uniqid from 'uniqid';
import { useDispatch } from 'react-redux';
import { onItemGo, onScrollCatalog } from '../../../redux/action';
import Popup from 'reactjs-popup';
import AcceptModul from './AcceptModul';
import Comm from '../../../asseets/Frame 348.png';
import Comm2 from '../../../asseets/Frame 349.png';

const ItemClientRequest = ({ FIO, date, id, orderName, phome, price, status, time, comm, img, clientId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dropDownComm, setDropDownComm] = useState(false);
  const [dropDownReqAccept, setDropDownReqAccept] = useState(false);
  const options = { weekday: 'long',  month: 'long', day: 'numeric' };
  const day = new Date(date);
  const toItem = () => {
    dispatch(onItemGo(true));
    dispatch(onScrollCatalog(window.pageYOffset));
  };
  
  const ReqAccept = () => {
    setDropDownReqAccept(!dropDownReqAccept);
  }

  return (
 <div>
    <div className={s.item_client_req_info_id}>{id}</div>
    <div className={s.item_bshop} key={uniqid()} >
          <div style = {{marginTop: '8px'}} className={s.item__bshop_image} onClick={() => ReqAccept()}>
              <img style = {{height: '60px', width: '60px', marginLeft: '3px'}}
                  src={img && img !== ''
                      ? 'https://bshop.kz/beauty/AvatarPhotos/' + img
                      : 'https://bshop.kz/beauty/AvatarPhotos/0.png'}
                  alt="logo" />

            <p className={s.item_client_req_fio}>{FIO}</p>
          </div>
          <div className={s.item_client_req_info}>
              <div style = {{marginBottom: '8px'}} className={s.item_client_req_info_name_id}>  
                  
                  <div style = {{width: '180px'}} className={s.item_client_req_info_date}>{orderName}</div>
                  {/* <div className={s.item_client_req_info_time}>{time}</div> */}

                  
              </div>
              {/* <div style = {{display: 'inline'}} className={s.item_client_req_info_nameClient}>Категория: {status}</div>
              <div></div>
              <div style = {{display: 'inline'}} className={s.item_client_req_info_nameClient}>Дата: {day.toLocaleDateString("ru-ru", options)}</div>
              <div></div>
              <div style = {{display: 'inline'}} className={s.item_client_req_info_nameClient}>Время: {time}</div>
              <div className={s.item_client_req_info_comm_price}>
                  <div 
                  style = {{marginLeft: '15px'}} 
                  className={s.item_client_req_info_price}>Цена: {price} тнг</div>
              </div> */}

                <div style = {{display: 'block', fontSize: '12px', float: 'left', marginLeft: '10px', lineHeight: '18px', fontFamily: 'Gotham Pro Regular'}} >Категория: <br/> Дата:<br/><br/> Время:<br/> Цена:</div>
                <div style = {{display: 'inline-block', marginBottom: '10px'}} className={s.item_client_req_info_nameClient}>{status}<br/>{day.toLocaleDateString("ru-ru", options)}<br/>{time}<br/><b>{price}</b> тг</div>
                
        {comm == '' ? <div  style = {{display: 'inline-block'}}> <img src={Comm2}/></div> : (
        <Popup trigger={ <div  style = {{display: 'inline-block'}}> <img src={Comm}/></div>} modal nested>
            {close => (
        <div style={{height: `216px`, marginTop: '210px'}} className={s.modal_comm}>
            <p><b>Комментарий клиента</b></p>
            <p><br/></p>
            <p>{comm}</p>
              <button onClick={close} style={{ textAlign: 'center', height: '32px', width: '152px', textDecoration: `none`, position: 'absolute', top: '370px', left: '70px'}} className={s.beauty_button_confirm}>Понятно</button>

        </div>
        )}
        </Popup>)}
        
                

           

          

              {/* <div className={s.item_client_req_info_comm      }>{comm}</div> */}
          </div>
      </div>
     
        <AcceptModul orderName={orderName} date={date} id={id} phome={phome} price={price} time={time} clientId={clientId} />

        <Popup trigger={<button style = {{color: '#404246', fontWeight: '400'}} className={s.dropdown_accept}>Принять заявку</button>} modal nested>
        <div style={{height: `80px`, marginTop: '210px'}} className={s.modal_aut}>
            <p>Для отправки заявки необходимо</p>

              <a style={{paddingTop: '7px', textAlign: 'center', height: '32px', width: '152px', textDecoration: `none`, marginTop: '5px'}} className={s.beauty_button_confirm} href='https://admin.bikli.kz/authfromweb'>авторизироваться</a>

      </div>

        </Popup>
                
        </div>
  );
};

export default ItemClientRequest;
