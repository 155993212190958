import React, { useEffect, useState } from 'react';
import beautyApi from '../../api/beautyApi';
import MyRequest from '../beauty/ItemClientReq/MyRequest';
import s from '../beauty/ServiceMasters/ServicesCatalog/ServiceWrapper.module.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import ServicesLoadingBlock from '../beauty/ServiceMasters/ServicesCatalog/ServicesLoadingBlock';
import { useSelector, useDispatch } from 'react-redux';
import { onSearchItemAllServ, onSearchItemAllServRequests, onScrollCatalog, AddCountItemAllServR, onAddAllServR} from '../../redux/action';
import uniqid from 'uniqid';
import ClientRequest from './ClientRequest';
const MyRequests = (props) => {
    const dispatch = useDispatch();
    const {RServicesValue, RServiceEnd, onShopId, onCategoryId } = useSelector((state) => state.onSearchItemAllServRequests);
    const AllServR = useSelector((state) => state.itemsAllServR);
    const ServItemsR = useSelector((state) => state.ServItemsR);
    const beenScroll = useSelector((state) => state.scrollCatalog);
    const myId = useSelector((state) => state.accountBeauty.id);
    const urlPath = window.location.pathname;
    var arrObjects = [];
    const makeUser = (id,  orderName,orderName2, orderName3, orderName4, orderName5,  date, time, price, comm, clientId) => {
        return { 

          id: id,
          orderName: orderName,
          orderName2: orderName2,
          orderName3: orderName3,
          orderName4: orderName4,
          orderName5: orderName5,
          date: date,
          time: time,
          price: price, 
          comm: comm,
          clientId: clientId,
          // ...другие свойства
        };
      }
  //   console.log('MastersWrapper')
    // console.log(AllServR)
    // формируем локальное хранилище: отправляем запрос, получаем данные (bshopApi) и записываем их в локальное хранилище (dispatch)
    const getServReq = async (RServiceEnd, RServicesValue, myId) => {
      
      const responseAllServR = await beautyApi.MyRequests(RServiceEnd, RServicesValue, myId);
    //  console.log(responseAllServR[0])

      for (let i = 0; i < responseAllServR.length; i++) {

        let user = makeUser(responseAllServR[i]['id'], 
        responseAllServR[i]['orderName'], responseAllServR[i]['orderName2'],responseAllServR[i]['orderName3'],responseAllServR[i]['orderName4'],responseAllServR[i]['orderName5'],
         responseAllServR[i]['date'], responseAllServR[i]['time'], responseAllServR[i]['price'], responseAllServR[i]['comm'], responseAllServR[i]['clientId'])
      //  console.log(user);
        arrObjects[i] = user;
        
        
      }
    //  console.log(arrObjects);
      dispatch(onAddAllServR(arrObjects.reverse()));
        
      if (beenScroll && urlPath.slice(0, 15) === '/shopper/shopid') {
        await window.scrollTo({
          top: beenScroll,
        });
      }
    };
    


    // console.log(AllServR)
    useEffect(() => {
      if (AllServR.length === 0) {
        //console.log('Первый ');
        getServReq(ServItemsR, '',myId);
      }
    }, []);
  
    useEffect(() => {
      if (AllServR.length !== 0) {
        if (RServicesValue === '' && onShopId === '' && onCategoryId === '') {
          //console.log('второй');
          getServReq(ServItemsR, '',myId);
        } else {
  
          getServReq(RServiceEnd, RServicesValue,myId);
        }
      }
    }, [ServItemsR, RServicesValue, RServiceEnd, myId]);
  
    const fetchNewItems = () => {
      dispatch(onScrollCatalog(''));
      if (RServicesValue === '' && onShopId === '' && onCategoryId === '') {
        dispatch(AddCountItemAllServR(ServItemsR + 2));
      } else {
        dispatch(
            onSearchItemAllServRequests({
            RServicesValue: RServicesValue,
            RServiceEnd: RServiceEnd + 2
          }),
        );
      }
    };

    // console.log(AllServR)
  
    return (
      
  <><div
            className={s.all_items}
            id="scrollableDiv"
            style={{ overflowY: 'scroll', overflowX: 'hidden' }}>

            {AllServR.length > 0 ? (
                <InfiniteScroll
                    style={{}}
                    dataLength={AllServR.length}
                    next={fetchNewItems}
                    hasMore={true}
                    loader={<div style={{ width: '50%' }}>
                        {/* {Array.from(Array(4), (_, i) => (
                              <LoadingBlock key={uniqid()} />
                            ))} */}
                    </div>}
                    sscrollableTarget="scrollableDiv">
                    {AllServR.map((item) => (
                        <MyRequest img = {props.img} key={uniqid()} {...item} />
                    ))}
                </InfiniteScroll>
            ) : (
              <><p style={{ marginBottom: '20px'}}>Вы еще создали ни одну заявку &#9203;</p><ClientRequest /></>
                // Array.from(Array(4), (_) => <ServicesLoadingBlock key={uniqid()} />)
            )}
        </div><div className={s.footer}>

            </div></>
    );
  };

export default React.memo(MyRequests);
