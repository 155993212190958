import React, { useCallback, useEffect, useRef, useState } from 'react';
import beautyApi from '../../api/beautyApi';
import ItemClientRequest from '../beauty/ItemClientReq/ItemClientRequest';
import s from '../beauty/ServiceMasters/ServicesCatalog/ServiceWrapper.module.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import ServicesLoadingBlock from '../beauty/ServiceMasters/ServicesCatalog/ServicesLoadingBlock';
import { useSelector, useDispatch } from 'react-redux';
import {
  onSearchItemAllServ,
  onSearchItemAllServRequests, 
  onScrollCatalog,
  AddCountItemAllServR,
  onAddAllServR,
} from '../../redux/action';
import uniqid from 'uniqid';
import { AiOutlineSync } from 'react-icons/ai';

const MasterRequest = (props) => {
  const dispatch = useDispatch();
  const { RServicesValue, RServiceEnd, onShopId, onCategoryId } = useSelector(
    (state) => state.onSearchItemAllServRequests,
  );
  const AllServR = useSelector((state) => state.itemsAllServR);
  const ServItemsR = useSelector((state) => state.ServItemsR);
  const beenScroll = useSelector((state) => state.scrollCatalog);
  const cat = useSelector((state) => state.CategoryOfMasters);
  let responseAllServR = null;
  const ws = useRef(null);
  const [data, setData] = useState(null);
  const urlPath = window.location.pathname;

  const gettingData = useCallback(() => {
    if (!ws.current) return;

    ws.current.onmessage = (e) => {
      //подписка на получение данных по вебсокету
      const message = JSON.parse(e.data);
      setData(message);
    };
  }, []);

  var arrObjects = [];
  const makeUser = (id, FIO, orderName, date, time, price, comm, clientId, status) => {
    return {
      id: id,
      FIO: FIO,
      orderName: orderName,
      date: date,
      time: time,
      price: price,
      comm: comm,
      clientId: clientId,
      status: status
      // ...другие свойства
    };
  };
  //   console.log('MastersWrapper')
  // console.log(AllServR)
  // формируем локальное хранилище: отправляем запрос, получаем данные (bshopApi) и записываем их в локальное хранилище (dispatch)
  const getServReq = async (RServiceEnd, RServicesValue) => {
    if (responseAllServR === null) {
      responseAllServR = await beautyApi.ClientServiceRequestsForInstagram(RServiceEnd, RServicesValue);
    } else {
      responseAllServR = data;
    }

    for (let i = 0; i < responseAllServR.length; i = i + 2) {
      let user = makeUser(
        responseAllServR[i].data['id'],
        responseAllServR[i].data['cat_id'],
        responseAllServR[i].data['orderName'],
        responseAllServR[i].data['date'],
        responseAllServR[i].data['time'],
        responseAllServR[i].data['price'],
        responseAllServR[i].data['comm'],
        responseAllServR[i + 1].client['id'],
        responseAllServR[i].data['status'],
      );
      arrObjects[i] = user;
    }
    dispatch(onAddAllServR(arrObjects.reverse()));
  };

  // console.log(AllServR)
  useEffect(() => {
    if (AllServR.length === 0) {
      //console.log('Первый ');
      getServReq(ServItemsR, '', '3', '');
    }
  }, []);

  useEffect(() => {
    if (AllServR.length !== 0) {
      if (RServicesValue === '' && onShopId === '' && onCategoryId === '') {
        //console.log('второй');
        getServReq(ServItemsR, '', '3', '');
      } else {
        getServReq(RServiceEnd, RServicesValue, onShopId, onCategoryId);
      }
    }
  }, [ServItemsR, RServicesValue, RServiceEnd, onShopId, onCategoryId, data]);

  const fetchNewItems = () => {
    dispatch(onScrollCatalog(''));
    if (RServicesValue === '' && onShopId === '' && onCategoryId === '') {
      dispatch(AddCountItemAllServR(ServItemsR + 2));
    } else {
      dispatch(
        onSearchItemAllServRequests({
          RServicesValue: RServicesValue,
          RServiceEnd: RServiceEnd + 2,
        }),
      );
    }
  };

  // console.log(AllServR)

  return (
    <>
      <div
        className={s.all_items}
        id="scrollableDiv"
        style={{ overflowY: 'scroll', overflowX: 'hidden' }}>

        {AllServR.length > 0 ? (
          <InfiniteScroll
            style={{}}
            dataLength={AllServR.length}
            next={fetchNewItems}
            hasMore={true}
            loader={
              <div style={{ width: '50%' }}>
                {/* {Array.from(Array(4), (_, i) => (
                              <LoadingBlock key={uniqid()} />
                            ))} */}
              </div>
            }
            sscrollableTarget="scrollableDiv">
            {AllServR.map((item) => (
              <ItemClientRequest key={uniqid()} {...item} />
            ))}
          </InfiniteScroll>
        ) : (
          Array.from(Array(4), (_) => <ServicesLoadingBlock key={uniqid()} />)
        )}
      </div>

      <div className={s.footer}></div>
    </>
  );
};

export default React.memo(MasterRequest);
