import React, { useRef, useState } from "react";
import "../../pages/beauty/style/Registration_form_style.css";
import Registration_text_field from "../../pages/beauty/Registration_text_field";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Inputmask from "inputmask";
import star from "../../../src/asseets/star.png";
const Registration_form = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const inputP = useRef();

  const [UserName, setName] = useState("");
  const [salon, setSalon] = useState("");
  const [adress, setAdress] = useState("");
  const [Phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [phoneInput, setphoneInput] = useState(false);

  // NEW
  const [verificationCode, setVerificationCode] = useState("");
  const [serverVerificationCode, setServerVerificationCode] = useState("");
  const [tab, setTab] = useState(0);
  // ************************

  const urlPath = localStorage.getItem("urlPath");
  const emregex = /\S+@\S+\.\S+/;

  inputP.current &&
    Inputmask({ mask: "+7 (999)-999-99-99" }).mask(inputP.current);

  

  const onPhoneInput = () => {
    inputP.current.value = "";
    setphoneInput(true);
  };

  const wave_error_phone = () => {
    toast("Ошибка! Некорректно введенное поле Телефон", {
      backgroundColor: "#323131",
      color: "#ffffff",
    });
  };
  const wave_error_name = () => {
    toast("Ошибка! Некорректно введенное поле Имя", {
      backgroundColor: "#323131",
      color: "#ffffff",
    });
  };
  const wave_error_email = () => {
    toast("Ошибка! Некорректно введенное поле Почты", {
      backgroundColor: "#323131",
      color: "#ffffff",
    });
  };
  const wave_error_adress = () => {
    toast("Ошибка! Некорректно введенное поле Адрес", {
      backgroundColor: "#323131",
      color: "#ffffff",
    });
  };
  const wave_success = () =>
    toast(
      "Отлично! Вы успешно зарегистрировались! Для входа используйте введенный номер телефона и введенный код (4 цифры)",
      {
        backgroundColor: "#10df3d",
        color: "#ffffff",
      }
    );
  const wave_yandex = () =>
    toast(
      'Похоже, что вы используете почту Яндекс, поэтому вам может прийти "пустое" сообщение. Для получения скрытой информации перейдите в свойства письма.',
      {
        backgroundColor: "#10df3d",
        color: "#ffffff",
      }
    );
  const wave_mail_not_success = () =>
    toast("Ошибка! Не удалось отправить письмо на указанную почту", {
      backgroundColor: "#10df3d",
      color: "#ffffff",
    });
  const wave_repeat_not_success = () =>
    toast("Ошибка! Клиент уже зарегистрирован!", {
      backgroundColor: "#323131",
      color: "#ffffff",
    });
  const undef_error = () =>
    toast("Возникла ошибка на стороне сервера. Попробуйте позже", {
      backgroundColor: "#323131",
      color: "#ffffff",
    });
    // NEW **************
  const verifyCode_error = () =>
    toast("Неправильно введен код. Попробуйте позже", {
      backgroundColor: "#323131",
      color: "#ffffff",
    });
    // **************************

  const PhoneCode = async () => {

    if (phoneInput == true) {
      if (inputP.current.inputmask.undoValue !== undefined) {
        if (inputP.current.inputmask.undoValue[17] !== "_") {
          if (inputP.current.inputmask.undoValue !== "") {
            if (UserName !== "") {
              if (adress !== "") {

                setTab(1);
                const phonedata = new FormData();
                let formatted_phone = Phone;
                formatted_phone = formatted_phone.replaceAll("-", "");
                formatted_phone = formatted_phone.replaceAll(" ", "");
                formatted_phone = formatted_phone.replaceAll("(", "");
                formatted_phone = formatted_phone.replaceAll(")", "");
                formatted_phone = formatted_phone.replace("+7", "8");
                phonedata.append("phone", formatted_phone);
                
                fetch(
                  `https://beauty.bikli.kz/beautyApi/flashcall.php`,
                  {
                    method: "POST",
                    body: phonedata,
                  }
                )
                .then(response => response.json())
                .then(res => setServerVerificationCode(res.code));
                
              } else {
                wave_error_adress();
              }
            } else {
              wave_error_name();
            }
          } else {
            wave_error_phone();
            }
        } else {
          wave_error_phone();
          }
      } else {
        wave_error_phone();
        }
    } else {
      wave_error_phone();
    }


  }

  const SendRegForm = async (e) => {
    if (phoneInput == true) {      
      if (inputP.current.inputmask.undoValue !== undefined) {
        if (inputP.current.inputmask.undoValue[17] !== "_") {
          if (inputP.current.inputmask.undoValue !== "") {
            if (UserName !== "") {
              if (adress !== "") {

                  const regdata = new FormData();
                  

                  regdata.append("FIO", UserName);
                  regdata.append("SalonName", salon);
                  regdata.append("Adress", adress);
                  regdata.append("Phone", Phone);
                  regdata.append("status", 1);
                  regdata.append("password", serverVerificationCode.substr(2,4));

                  let response = await fetch(
                    `https://bikli.kz/apiBeauty/BRegistration_form3.php`,
                    {
                      method: "POST",
                      body: regdata,
                    }
                  );
                  let commits = await response.json(); // читаем ответ в формате JSON
                  const PHPresponse = commits[0].response;
                  console.log(PHPresponse);

                  PHPresponse == "Успешно"
                    ? wave_success()
                    : PHPresponse == "Клиент уже зарегистрирован!"
                    ? wave_repeat_not_success()
                    : PHPresponse == "Ошибка отправки письма"
                    ? wave_success()
                    : undef_error();

                  if (email.indexOf("yandex") + 1) {
                    wave_yandex();
                  }

              } else {
                wave_error_adress();
              }
            } else {
              wave_error_name();
            }
          } else {
            wave_error_phone();
          }
        } else {
          wave_error_phone();
        }
      } else {
        wave_error_phone();
        }
    } else {
      wave_error_phone();
    }
  };

  return (
    <>
      <div className="reg_body">
        <div style={{ marginTop: "20px" }} className="reg_wrapper">
          <h1 className="reg_title" style={{ marginTop: "10px" }}>
            Регистрация
          </h1>
        <div className="registration_form">

            <>
              <p style={{ marginLeft: "-165px", display: "inline" }}>Имя</p>{" "}
              <img style={{ marginBottom: "8px" }} src={star}></img>
              <Registration_text_field
                name="password"
                onChange={(e) => setName(e.target.value)}
                error="none"
                // placeholder="Пароль"
                className="reg_placeholder"
              />
              <p style={{ marginLeft: "-90px", display: "inline" }}>
                Название салона
              </p>
              <Registration_text_field
                name="password"
                onChange={(e) => setSalon(e.target.value)}
                error="none"
                // placeholder="Пароль"
                className="reg_placeholder"
              />
              <p style={{ marginLeft: "-35px", display: "inline" }}>
                Адрес оказания услуги
              </p>{" "}
              <img style={{ marginBottom: "8px" }} src={star}></img>
              <Registration_text_field
                name="password"
                onChange={(e) => setAdress(e.target.value)}
                error="none"
                // placeholder="Пароль"
                className="reg_placeholder"
              />
              <p style={{ marginLeft: "-135px", display: "inline" }}>Телефон</p>{" "}
              <img style={{ marginBottom: "8px" }} src={star}></img>
              <div className="inputGroup">
                <input
                  ref={inputP}
                  className="reg_placeholder"
                  type="text"
                  onChange={(e) => setPhone(e.target.value)}
                  onClick={() => {
                    onPhoneInput();
                  }}
                />
              </div>
              <div style={{ display: tab == 0 ? 'block' : 'none' }} className="inputGroup">
                <button
                  onClick={PhoneCode}
                  className="reg_btn"
                  style={{ marginBottom: 20 }}
                >
                  Получить код
                </button>
                <p>Мы позвоним на указанный номер, вашим кодом будут последние 4 цифры звонившего номера</p>

              </div>


              <div style={{ display: tab == 1 ? 'block' : 'none' }}>
              <p style={{ marginLeft: "25px", display: "inline" }}>
                Введите последние 4 цифр номера
              </p>
              

              <Registration_text_field
                name="passwordCode"
                onChange={(e) => setVerificationCode(e.target.value)}
                error="none"
                placeholder="Код"
                className="reg_placeholder"
                // label={"Код"}
              />

<input type="checkbox" />
              <div className="inputGroup">

               
                <button
                  onClick={(e) => {
                    if (verificationCode == serverVerificationCode.substr(2,4)) {
                      SendRegForm();
                      console.log("success");
                    } else {
                      verifyCode_error();
                      console.log("error");
                    }
                  }}
                  className="reg_btn"
                  style={{ marginBottom: 20 }}
                >
                  Продолжить
                </button>

              </div>
              </div>




              <button
                  className="reg_abs"
                  style={{ textAlign: "center", marginBottom: 10 }}
                  onClick={() => history.goBack()}
                >
                  назад
              </button>
            </>

                <div style={{ marginBottom: "130px" }}>
                  <img style={{ display: "inline" }} src={star}></img>
                  <p style={{ marginLeft: "10px", display: "inline" }}>
                    {" "}
                    поля обязательные к заполнению
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ToastContainer
                    position="top-center"
                    theme="dark"
                    limit={3}
                  />
                </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration_form;
