import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  accountBeauty,
  onServMastersMenu
 } from '../../../redux/action';

import s from './service.module.css';

import ServiceType from './ServiceType';
import PublicationWrapper from '../Publications/PublicationWrapper';

import hair from '../../../asseets/hair.png';
import lips from '../../../asseets/lips.png';
import nails from '../../../asseets/nails.png';
import eyelashes from '../../../asseets/eyelashes.png';
import brows from '../../../asseets/brows.png';
// import legs from '../../../asseets/legs.png';

import uhodZaTelom from '../../../asseets/Массаж.png';
import maki from '../../../asseets/Макияж.png';
import barber from '../../../asseets/Барбер.png';
import pirs from '../../../asseets/Пирсинг.png';
import epil from '../../../asseets/Эпиляция.png';

const ServiceMenu = (props) => {
  const dispatch = useDispatch();
  const login = JSON.parse(localStorage.getItem('account'));
  const [dropDownFilter, setDropDownFilter] = useState(false);
  const link = `/beauty/masters`;
  const eng_catname_hair = `hair`;
  const eng_catname_lips = `lips`;
  const eng_catname_nails = `nails`;
  const eng_catname_eyelashes = `eyelashes`;
  const eng_catname_brows = `brows`;
  const eng_catname_legs = `legs`;

  const ru_catname_hair = `Волосы`;
  const ru_catname_lips = `Макияж`;
  const ru_catname_nails = `Ногти`;
  const ru_catname_eyelashes = `Ресницы`;
  const ru_catname_brows = `Брови`;
  const ru_catname_legs = `Уход за телом`;
  const ru_catname_hair2 = `Удаление волос`;
  const ru_catname_kosm = `Космето-логия`;
  const ru_catname_tatu = `Тату и пирсинг`;
  const ru_catname_barber = `Барбер-шоп`;

  const catID_hair = `3`;
  const catID_lips = `6`;
  const catID_nails = `4`;
  const catID_eyelashes = `2`;
  const catID_brows = `1`;
  const catID_legs = `5`;
  const catID_hair2 = `7`;
  const catID_kosm = `8`;
  const catID_tatu = `9`;

  useEffect(() => {
    if (login) {
      const regdata = new FormData();

      regdata.append('phone', login.login);
      regdata.append('pass', login.pass);
      regdata.append('status', 0);

      fetch(`https://bshop.kz/beauty/beautyApi/profil/logIn.php`, {
        method: 'POST',
        body: regdata,
      })
        .then(async (res) => await res.json())
        .then((res) => {
          dispatch(accountBeauty(res[0]));
        });
    }

    dispatch(onServMastersMenu('Запись'))
  }, []);
  // https://www.bshop.kz/beauty/Service/1.png
  return (
    <>
      {
        <div className={s.root}>
          <div className={s.services_wrapper}>
            <div>
            <div className={s.services}>
      
              <ServiceType img={hair} link={link} cat={eng_catname_hair} cat_ru={ru_catname_hair} mainCatID={catID_hair}/>
              <ServiceType img={maki} link={link} cat={eng_catname_lips} cat_ru={ru_catname_lips} mainCatID={catID_lips}/>
              <ServiceType img={nails} link={link} cat={eng_catname_nails} cat_ru={ru_catname_nails} mainCatID={catID_nails}/>
              <ServiceType img={eyelashes} link={link} cat={eng_catname_eyelashes} cat_ru={ru_catname_eyelashes} mainCatID={catID_eyelashes}/>
              <ServiceType img={brows} link={link} cat={eng_catname_brows} cat_ru={ru_catname_brows} mainCatID={catID_brows}/>
              <ServiceType img={uhodZaTelom} link={link} cat={eng_catname_legs} cat_ru={ru_catname_legs} mainCatID={catID_legs}/>
              <ServiceType img={epil} link={link} cat={eng_catname_hair} cat_ru={ru_catname_hair2} mainCatID={catID_hair2}/>
              <ServiceType img={lips} link={link} cat={eng_catname_lips} cat_ru={ru_catname_kosm} mainCatID={catID_kosm}/>
              <ServiceType img={pirs} link={link} cat={eng_catname_nails} cat_ru={ru_catname_tatu} mainCatID={catID_tatu}/>

              <ServiceType img={barber} link={link} cat={eng_catname_lips} cat_ru={ru_catname_barber} mainCatID={catID_kosm}/>

            </div>
            </div>



            <div className={s.dropdown_filters} onClick={() => setDropDownFilter(!dropDownFilter)}>
              {!dropDownFilter ? (
                <svg
                  width="340"
                  height="8"
                  viewBox="0 0 16 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0L8 8L16 2.79753e-06" fill="#ffffff" />
                </svg>
              ) : (
                <svg
                  width="340"
                  height="8"
                  viewBox="0 0 16 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 8L8 6.99382e-07L0 8" fill="#ffffff" />
                </svg>
              )}
            </div>

            {dropDownFilter && (
              <PublicationWrapper />
            )}
          </div>
          
        </div>
      }
    </>
  );
};

export default ServiceMenu;
