const initialState = {
  typeSection: '',

  error: false,

  data: [],
  user: [],

  itemCafe: [],

  listProductCafe: [],

  itemCategory: [],

  basket: [],

  history: [],

  gifts: [],

  adressFromUserDelivery: [],

  numItemsInBasket: 0,

  totalPrice: 0,

  deliveryPrice: 0,

  bodyOverflowToCard: true,

  basketBshop: [],

  onSearchItemBshop: { value: '', end: '100', onShopId: '', onCategoryId: '' },
  onSearchItemPubl: { publvalue: '', end: '100', onShopId: '', onCategoryId: '' },
  onSearchItemAllServ: { ServicesValue: '', ServiceEnd: '100', onShopId: '', onCategoryId: '' },
  onSearchItemAllServRequests: { RServicesValue: '', RServiceEnd: '100', onShopId: '', onCategoryId: '' },
  toShop: false,

  toItem: false,

  scrollCatalog: '',

  accountBeauty: [],

  itemsBshop: [],
  itemsPubl: [],
  itemsAllServ: [],
  itemsAllServR: [],
  itemsAllAcceptedServR: [],
  itemsAllMasterServR: [],
  masterAbout: [],
  itemsAllServForClientReq: [],
  itemsAllSDServsForClientReq: [],
  itemsChildAllServForClientReq: [],
  itemsChildAllSDServsForClientReq: [],

  itemsServForClientReq: '',
  itemsServForClientReq_1: '',
  itemsServForClientReq_2: '',
  itemsServForClientReq_3: '',
  itemsServForClientReq_4: '',
  itemsServForClientReq_5: '',
  itemsServForClientReq_11: '',
  itemsServForClientReq_21: '',
  itemsServForClientReq_31: '',
  itemsServForClientReq_41: '',
  itemsServForClientReq_51: '',

  authDisplay: 'none',

  SaveitemsServForClientReq: false,
  SaveitemsServForClientReq_1: '',
  SaveitemsServForClientReq_2: '',
  SaveitemsServForClientReq_3: '',
  SaveitemsServForClientReq_4: '',
  SaveitemsServForClientReq_5: '',
  SaveitemsServForClientReq_11: '',
  SaveitemsServForClientReq_21: '',
  SaveitemsServForClientReq_31: '',
  SaveitemsServForClientReq_41: '',
  SaveitemsServForClientReq_51: '',

  SaveitemsServForClientDate: '',
  SaveitemsServForClientTime: '',
  SaveitemsServForClientPrice: '',
  
  itemsAllServForClientMainReq: [],
  counItems: 50,
  PublItems: 4,
  ServItems: 4,
  ServItemsR: 4,

  DisplayType: 'flex',
  DopDisplayType: 'block',
  DisplayType1: 'none',
  DisplayType2: 'none',
  DisplayType3: 'none',
  DisplayType4: 'none',

  PageDopDisplayType: 'block',
  PageDisplayType1: 'none',
  PageDisplayType2: 'none',
  PageDisplayType3: 'none',
  PageDisplayType4: 'none',

  PBCborderBottom: '4px solid transparent',
  PBCborderImage: 'linear-gradient(to left, rgb(212, 50, 216) -0.07%, rgb(255, 96, 141) 51.49%, rgb(244, 165, 130) 99.93%) 1 / 1 / 0 stretch',
  PBCcolor: '#404246',
  PBCfontWeight: '800',

  PBCmenu: 'мои записи',
  ServMastersMenu: 'Запись'
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    
    case 'TO_SET_AUTHDISPLAY': {
      return {
        ...state,
        authDisplay: action.payload,
      };
    }

    case 'TO_ON_SERVMASTERS_MENU': {
      return {
        ...state,
        ServMastersMenu: action.payload,
      };
    }

    case 'TO_ON_PBC_MENU': {
      return {
        ...state,
        PBCmenu: action.payload,
      };
    }

    case 'TO_ON_PBC_BORDER_BOTTOM': {
      return {
        ...state,
        PBCborderBottom: action.payload,
      };
    }
    
    case 'TO_ON_PBC_BORDER_IMAGE': {
      return {
        ...state,
        PBCborderImage: action.payload,
      };
    }

    case 'TO_ON_PBC_COLOR': {
      return {
        ...state,
        PBCcolor: action.payload,
      };
    }
    
    case 'TO_ON_PBC_FONT_WEIGHT': {
      return {
        ...state,
        PBCfontWeight: action.payload,
      };
    }
  

    case 'DISPLAY_FLEX': {
      return {
        ...state,
        DisplayType: action.payload,
      };
    }

    case 'DISPLAY_FLEX1': {
      return {
        ...state,
        DisplayType1: action.payload,
      };
    }

    case 'DISPLAY_FLEX2': {
      return {
        ...state,
        DisplayType2: action.payload,
      };
    }

    case 'DISPLAY_FLEX3': {
      return {
        ...state,
        DisplayType3: action.payload,
      };
    }

    case 'DISPLAY_FLEX4': {
      return {
        ...state,
        DisplayType4: action.payload,
      };
    }


    case 'DOP_DISPLAY_FLEX': {
      return {
        ...state,
        DopDisplayType: action.payload,
      };
    }

    case 'PAGE_DISPLAY_FLEX1': {
      return {
        ...state,
        PageDisplayType1: action.payload,
      };
    }

    case 'PAGE_DISPLAY_FLEX2': {
      return {
        ...state,
        PageDisplayType2: action.payload,
      };
    }

    case 'PAGE_DISPLAY_FLEX3': {
      return {
        ...state,
        PageDisplayType3: action.payload,
      };
    }

    case 'PAGE_DISPLAY_FLEX4': {
      return {
        ...state,
        PageDisplayType4: action.payload,
      };
    }


    case 'PAGE_DOP_DISPLAY_FLEX': {
      return {
        ...state,
        PageDopDisplayType: action.payload,
      };
    }


    case 'CHOOSE_TYPE_SECTION': {
      return {
        ...state,
        typeSection: action.typeSection,
      };
    }

    case 'SET_DATE': {
      return {
        ...state,
        [action.item]: action.payload.response,
      };
    }

    case 'GET_USER_DATA': {
      return {
        ...state,
        user: [action.payload.response.user],
      };
    }

    case 'SET_ITEM': {
      const itemCafe = action.payload.response.filter((el) => el.ListProduct);
      const listProductCafe = Object.assign({}, ...action.payload.response).ListProduct;
      return {
        ...state,
        [action.item]: itemCafe,
        listProductCafe,
      };
    }

    case 'SET_ITEM_CATEGORY': {
      return {
        ...state,
        itemCategory: action.payload.response,
      };
    }

    case 'BODY_OVERFLOW_TO_CARD': {
      return {
        ...state,
        bodyOverflowToCard: Boolean(action.state),
      };
    }

    // case 'FILTER_CATEGORY': {
    //     const listProductCafe = Object.assign({}, ...state.itemCafe).ListProduct;
    //     const filterList = listProductCafe.filter(el => el.idcategory === action.payload)
    //     return {
    //         ...state,
    //         listProductCafe: filterList
    //     }
    // }

    case 'ADD_ITEM_BASKET': {
      const newBasket = state.basket.some((el) => el.id === action.payload.id)
        ? state.basket.map((el) => {
            if (el.id === action.payload.id) {
              return {
                ...el,
                amount: el.amount + action.payload.amount,
                count: el.count + action.payload.count,
              };
            }
            return el;
          })
        : [...state.basket, action.payload];
      const totalPrice = newBasket.reduce((acc, el) => acc + el.amount, 0);
      const numItemsInBasket = newBasket.reduce((acc, el) => acc + el.count, 0);
      const uniqueItem = newBasket.filter(
        (el, index, self) => index === self.findIndex((t) => t.idcafe === el.idcafe),
      ); //удаление дубликатов по idCafe
      const deliveryPrice = state.data
        .filter((el) => uniqueItem.some((el2) => +el.id === +el2.idcafe)) //ищет по id товара Кафе
        .reduce((acc, el) => +acc + +el.cenadost, 0); //суммирует сумму доставки

      return {
        ...state,
        basket: newBasket,
        totalPrice,
        numItemsInBasket,
        deliveryPrice,
      };
    }

    case 'COUNT_ITEM': {
      const newBasket = state.basket.map((el) => {
        if (el.count === 0 && action.operator === '-') {
          return el;
        }
        if (el.id === action.payload) {
          return (el =
            action.operator === '-'
              ? { ...el, count: el.count - 1 }
              : { ...el, count: el.count + 1 });
        }
        return el;
      });

      let totalPrice =
        action.operator === '-'
          ? state.totalPrice - +action.item.cenaeda
          : state.totalPrice + +action.item.cenaeda;

      //сумма доставки при удалении из корзины
      //проdерка на count = 0, если step1 не пустой массив, значит какой то товар удалили из карзины
      let step1 = newBasket.filter((el) => el.count === 0);

      //нашли такие же товары с одной кафе, если товар не один, то дальше можно не идти
      let step2 = newBasket.filter((el) => el.idcafe === Object.assign({}, ...step1).idcafe);

      //нашли ресторан в котором заказали товар из step2
      let step3 =
        step2.length > 1
          ? []
          : state.data.filter((el) => step2.some((el2) => +el.id === +el2.idcafe));

      //проверяем, если step3 равен [], значит есть товар с этого же кафе. в противном же случае step3 равен кафе
      //из которого берем цену доставки и удаляем
      let newDeliveryPrice = step3.length
        ? state.deliveryPrice - +Object.assign({}, ...step3).cenadost
        : state.deliveryPrice;

      const basketWithOutNull = newBasket.filter((el) => el.count !== 0);
      const numItemsInBasket = basketWithOutNull.reduce((acc, el) => acc + el.count, 0);

      return {
        ...state,
        basket: basketWithOutNull,
        totalPrice,
        numItemsInBasket,
        deliveryPrice: newDeliveryPrice,
      };
    }

    case 'ON_EMPTY_BASKET': {
      return {
        ...state,
        basket: [],
        numItemsInBasket: 0,
        totalPrice: 0,
      };
    }

    case 'DEL_ADRESS_DELIVERY_BASKET': {
      const adressFromUserDelivery = state.adressFromUserDelivery.filter(
        (el) => el.id !== action.payload,
      );
      return {
        ...state,
        adressFromUserDelivery,
      };
    }

    case 'LOG_OUT': {
      return {
        ...state,
        data: [],
        user: [],
        itemCafe: [],
        listProductCafe: [],
        itemCategory: [],
        basket: [],
        history: [],
        gifts: [],
        adressFromUserDelivery: [],
        numItemsInBasket: 0,
        totalPrice: 0,
        deliveryPrice: 0,
      };
    }

    case 'ADD_ITEM_BASKET_BSHOP': {
      return {
        ...state,
        // asd: console.log(Boolean(action.payload)),
        basketBshop: action.payload ? [...state.basketBshop, action.payload] : [],
      };
    }
    case 'REMOVE_ITEM_BASKET_BSHOP': {
      return {
        ...state,
        basketBshop: state.basketBshop.filter(
          (item) =>
            item.nameItem.join() !== action.payload.nameItem.join() &&
            item.id.join() !== action.payload.id.join(),
        ),
      };
    }
    case 'ON_SEARCH_ITEM_BSHOP': {
      return {
        ...state,
        onSearchItemBshop: action.payload,
      };
    }

    case 'ON_SEARCH_ITEM_PUBL': {
      return {
        ...state,
        onSearchItemPubl: action.payload,
      };
    }

    case 'ON_SEARCH_ITEM_ALL_SERV': {
      return {
        ...state,
        onSearchItemAllServ: action.payload,
      };
    }

    case 'ON_SEARCH_ITEM_ALL_SERV_REQUESTS': {
      return {
        ...state,
        onSearchItemAllServRequests: action.payload,
      };
    }

    case 'TO_SHOP': {
      return {
        ...state,
        toShop: action.payload,
      };
    }
    case 'TO_ITEM': {
      return {
        ...state,
        toItem: action.payload,
      };
    }
    
    case 'TO_SCROLL_CATALOG': {
      return {
        ...state,
        scrollCatalog: action.payload,
      };
    }

    case 'TO_MASTERS_CATEGORY': {
      return {
        ...state,
        CategoryOfMasters: action.payload,
      };
    }

    case 'TO_ADD_ITEM_BSHOP': {
      return {
        ...state,
        itemsBshop: action.payload,
      };
    }

    case 'TO_ADD_ITEM_PUBL': {
      return {
        ...state,
        itemsPubl: action.payload,
      };
    }

    case 'TO_ADD_ITEM_ALL_SERV': {
      return {
        ...state,
        itemsAllServ: action.payload,
      };
    }

    case 'TO_ADD_ITEM_ALL_SERV_R': {
      return {
        ...state,
        itemsAllServR: action.payload,
      };
    }
    
    case 'TO_ADD_ITEM_ALL_ACCEPTED_SERV_R': {
      return {
        ...state,
        itemsAllAcceptedServR: action.payload,
      };
    }

    case 'TO_ADD_ITEM_ALL_MASTER_SERV_R': {
      return {
        ...state,
        itemsAllMasterServR: action.payload,
      };
    }


    case 'TO_ADD_MASTER_FOR_CLIENT_INFO': {
      return {
        ...state,
        masterAbout: action.payload,
      };
    }

    case 'TO_ADD_ITEM_ALL_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        itemsAllServForClientReq: action.payload,
      };
    }

    case 'TO_ADD_CHILD_ITEM_ALL_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        itemsChildAllServForClientReq: action.payload,
      };
    }

    case 'TO_ADD_ITEM_ALL_SECOND_DOP_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        itemsAllSDServsForClientReq: action.payload,
      };
    }

    case 'TO_ADD_CHILD_ITEM_ALL_SECOND_DOP_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        itemsChildAllSDServsForClientReq: action.payload,
      };
    }


    case 'TO_ADD_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        itemsServForClientReq: action.payload,
      };
    }

    case 'TO_ADD_1_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        itemsServForClientReq_1: action.payload,
      };
    }

    case 'TO_ADD_11_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        itemsServForClientReq_11: action.payload,
      };
    }
    
    case 'TO_ADD_2_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        itemsServForClientReq_2: action.payload,
      };
    }

    case 'TO_ADD_21_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        itemsServForClientReq_21: action.payload,
      };
    }

    case 'TO_ADD_3_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        itemsServForClientReq_3: action.payload,
      };
    }

    case 'TO_ADD_31_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        itemsServForClientReq_31: action.payload,
      };
    }

    case 'TO_ADD_4_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        itemsServForClientReq_4: action.payload,
      };
    }

    case 'TO_ADD_41_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        itemsServForClientReq_41: action.payload,
      };
    }

    case 'TO_ADD_5_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        itemsServForClientReq_5: action.payload,
      };
    }

    case 'TO_ADD_51_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        itemsServForClientReq_51: action.payload,
      };
    }

    case 'TO_ADD_ITEM_ALL_SERV_FOR_CLIENT_MAIN_REQ': {
      return {
        ...state,
        itemsAllServForClientMainReq: action.payload,
      };
    }

    case 'TO_ADD_COUNT_ITEMS_BSHOP': {
      return {
        ...state,
        counItems: action.payload,
      };
    }

    case 'TO_ADD_COUNT_ITEMS_PUBL': {
      return {
        ...state,
        PublItems: action.payload,
      };
    }

    case 'TO_ADD_COUNT_ITEMS_ALL_SERV': {
      return {
        ...state,
        ServItems: action.payload,
      };
    }

    case 'TO_ADD_COUNT_ITEMS_ALL_SERV_R': {
      return {
        ...state,
        ServItemsR: action.payload,
      };
    }

    case 'TO_ON_ACCOUNT_BEAUTY': {
      return {
        ...state,
        accountBeauty: action.payload,
      };
    }

    case 'ERROR': {
      return {
        ...state,
        error: true,
      };
    }

    case 'TO_SAFE_AND_ADD_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        SaveitemsServForClientReq: action.payload,
      };
    }

    case 'TO_SAFE_AND_ADD_1_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        SaveitemsServForClientReq_1: action.payload,
      };
    }

    case 'TO_SAFE_AND_ADD_11_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        SaveitemsServForClientReq_11: action.payload,
      };
    }
    
    case 'TO_SAFE_AND_ADD_2_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        SaveitemsServForClientReq_2: action.payload,
      };
    }

    case 'TO_SAFE_AND_ADD_21_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        SaveitemsServForClientReq_21: action.payload,
      };
    }

    case 'TO_SAFE_AND_ADD_3_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        SaveitemsServForClientReq_3: action.payload,
      };
    }

    case 'TO_SAFE_AND_ADD_31_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        SaveitemsServForClientReq_31: action.payload,
      };
    }

    case 'TO_SAFE_AND_ADD_4_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        SaveitemsServForClientReq_4: action.payload,
      };
    }

    case 'TO_SAFE_AND_ADD_41_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        SaveitemsServForClientReq_41: action.payload,
      };
    }

    case 'TO_SAFE_AND_ADD_5_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        SaveitemsServForClientReq_5: action.payload,
      };
    }

    case 'TO_SAFE_AND_ADD_51_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        SaveitemsServForClientReq_51: action.payload,
      };
    }

    case 'TO_SAFE_AND_ADD_DATE_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        SaveitemsServForClienDate: action.payload,
      };
    }

    case 'TO_SAFE_AND_ADD_TIME_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        SaveitemsServForClientTime: action.payload,
      };
    }

    case 'TO_SAFE_AND_ADD_PRICE_ITEM_SERV_FOR_CLIENT_REQ': {
      return {
        ...state,
        SaveitemsServForClientPrice: action.payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
