
import React, { useCallback, useRef, useEffect, useState } from 'react';
import beautyApi from '../../api/beautyApi';
import MyAcceptedRequestForClient from '../beauty/ItemClientReq/MyAcceptedRequestForClient';
import s from '../beauty/ServiceMasters/ServicesCatalog/ServiceWrapper.module.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import ServicesLoadingBlock from '../beauty/ServiceMasters/ServicesCatalog/ServicesLoadingBlock';
import { useSelector, useDispatch } from 'react-redux';
import { onSearchItemAllServ, onSearchItemAllServRequests, onScrollCatalog, AddCountItemAllServR, onAddAllServR, onAddAllAcceptedServR, onAddMasterForClientInfo
  , onPBCborderBottom, onPBCborderImage, onPBCcolor, onPBCfontWeight} from '../../redux/action';
import uniqid from 'uniqid';
import ClientRequest from './ClientRequest';

const MyAcceptedRequests = (props) => {
    const dispatch = useDispatch();
    const {RServicesValue, RServiceEnd, onShopId, onCategoryId } = useSelector((state) => state.onSearchItemAllServRequests);
    const AllServR = useSelector((state) => state.itemsAllAcceptedServR);
    const ServItemsR = useSelector((state) => state.ServItemsR);
    const beenScroll = useSelector((state) => state.scrollCatalog);
    const myId = useSelector((state) => state.accountBeauty.id);
    const urlPath = window.location.pathname;
    const masterAbout = useSelector((state) => state.masterAbout);
    const [data, setData] = useState(null);
    const ws = useRef(null);



    useEffect(() => {
  

      dispatch(onPBCborderBottom('4px solid transparent'));
      dispatch(onPBCborderImage('linear-gradient(to left, rgb(212, 50, 216) -0.07%, rgb(255, 96, 141) 51.49%, rgb(244, 165, 130) 99.93%) 1 / 1 / 0 stretch'));
      dispatch(onPBCcolor('#404246'));
      dispatch(onPBCfontWeight('800'));
      
        ws.current = new WebSocket('wss:/beauty.bikli.kz:2346'); // создаем ws соединение
        ws.current.onopen = () => {
          // console.log('Соединение открыто');
        }; // callback на ивент открытия соединения
        ws.current.onclose = () => {
          // console.log('Соединение закрыто');
        }; // callback на ивент закрытия соединения
        gettingData();
        return () => ws.current.close(); // кода меняется isPaused - соединение закрывается
      
    }, []);

    const gettingData = useCallback(() => {
      if (!ws.current) return;
  
      ws.current.onmessage = (e) => {
        //подписка на получение данных по вебсокету
        const message = JSON.parse(e.data);
        // console.log( message[0].MasterAccepted)
        // console.log( message[0].allOrder)
        if (message[0].MasterAccepted) {
          message[0].MasterAccepted[0].data.clientId === myId && setData(message[0].MasterAccepted);
        }  else if (message[0].masterAoR) {
          message[0].masterAoR[0].data.clientId === myId && setData(message[0].masterAoR);
        }
        // console.log(message[0].MasterAccepted)
      };
    }, []);

    useEffect(() => {
      getServReq(ServItemsR, '',myId);
    }, [data]);

    const WSCSend = (send) => {
      ws.current.send(send);
      setData(arrObjects);
    }

    var MasterObj = [];
    var arrObjects = [];
    const makeUser = (orderId, id,  orderName, orderName2, orderName3, orderName4, orderName5, date, time, price, comm, clientId, MasterID, status, Adress, Email, FIO, Phone, SalonName, img) => {
        return { 
          orderId:orderId, 
          id: id,
          orderName: orderName,
          orderName2: orderName2,
          orderName3: orderName3,
          orderName4: orderName4,
          orderName5: orderName5,
          date: date,
          time: time,
          price: price, 
          comm: comm,
          clientId: clientId,
          MasterID: MasterID,
          status: status,
          Adress: Adress,
          Email: Email,
          FIO: FIO,
          Phone: Phone,
          SalonName, SalonName,
          img: img
          // ...другие свойства
        };
      }
  //   console.log('MastersWrapper')
    // console.log(AllServR)
    // формируем локальное хранилище: отправляем запрос, получаем данные (bshopApi) и записываем их в локальное хранилище (dispatch)
    const getServReq = async (RServiceEnd, RServicesValue, myId) => {
      
      const responseAllServR = await beautyApi.MyAcceptedRequests(RServiceEnd, RServicesValue, myId);
      // console.log(responseAllServR)
    //  console.log(responseAllServR[0])

      // for (let i = 0; i < responseAllServR.length; i++) {
      //   let responseAllServRA = await beautyApi.MyAcceptedRequestMaster(responseAllServR[i]['masterId']);  
      //   let user = makeUser(responseAllServR[i]['orderId'], responseAllServR[i]['id'], responseAllServR[i]['orderName'] , responseAllServR[i]['orderName2']
      //   , responseAllServR[i]['orderName3'] , responseAllServR[i]['orderName4'] , responseAllServR[i]['orderName5'] , responseAllServR[i]['date'],
      //                       responseAllServR[i]['time'], responseAllServR[i]['price'], responseAllServR[i]['comm'], responseAllServR[i]['clientId'], responseAllServR[i]['masterId'],
      //                        responseAllServRA[0]['Adress'] , responseAllServRA[0]['Email']
      //   , responseAllServRA[0]['FIO'] , responseAllServRA[0]['Phone'] , responseAllServRA[0]['SalonName'],
      //   responseAllServRA[0]['img'])
      // //  console.log(user);
      //   arrObjects[i] = user;
        
      // }
    for (let i = 0; i < responseAllServR.length; i = i + 2) {
      let user = makeUser(
        responseAllServR[i].data['orderId'],
        responseAllServR[i].data['id'],
        responseAllServR[i].data['orderName'],
        responseAllServR[i].data['orderName2'],
        responseAllServR[i].data['orderName3'],
        responseAllServR[i].data['orderName4'],
        responseAllServR[i].data['orderName5'],
        responseAllServR[i].data['date'],
        responseAllServR[i].data['time'],
        responseAllServR[i].data['price'],
        responseAllServR[i].data['comm'],
        responseAllServR[i].data['clientId'],
        responseAllServR[i].data['masterId'],
        responseAllServR[i].data['status'],

        responseAllServR[i + 1].master['Adress'],
        responseAllServR[i + 1].master['Email'],
        responseAllServR[i + 1].master['FIO'],
        responseAllServR[i + 1].master['Phone'],
        responseAllServR[i + 1].master['SalonName'],
        responseAllServR[i + 1].master['img'],

      );
      arrObjects[i] = user;
    }
    //  console.log(arrObjects);
      dispatch(onAddAllAcceptedServR(arrObjects.reverse()));
    //   console.log(123)
    //   dispatch(onAddMasterForClientInfo(MasterObj));
    //   console.log(123)
      if (beenScroll && urlPath.slice(0, 15) === '/shopper/shopid') {
        await window.scrollTo({
          top: beenScroll,
        });
      }
    };

    // const getMaster = async (MasterID) => {
    //     const responseAllServR = await beautyApi.MyAcceptedRequestMaster(MasterID);  
    //     for (let i = 0; i < responseAllServR.length; i++) {
    //       let user = Master(responseAllServR[i]['id'], responseAllServR[i]['Adress'] , responseAllServR[i]['Email']
    //       , responseAllServR[i]['FIO'] , responseAllServR[i]['Phone'] , responseAllServR[i]['SalonName'] , responseAllServR[i]['dateCreate'],
    //                           responseAllServR[i]['img'])
    //       MasterObj = user;
    //     }
    //     dispatch(onAddMasterForClientInfo(MasterObj));
    //   };

      const Master = (id,  Adress, Email, FIO, Phone, SalonName, dateCreate, img) => {
        return { 

          id: id,
          Adress: Adress,
          Email: Email,
          FIO: FIO,
          Phone: Phone,
          SalonName: SalonName,
          dateCreate: dateCreate,
          img: img,
          // ...другие свойства
        };
      }
    


    // console.log(AllServR)
    useEffect(() => {
      if (AllServR.length === 0) {
        //console.log('Первый ');
        getServReq(ServItemsR, '',myId);
      }

    }, []);
  
    useEffect(() => {
      if (AllServR.length !== 0) {
        if (RServicesValue === '' && onShopId === '' && onCategoryId === '') {
          //console.log('второй');
          getServReq(ServItemsR, '',myId);
        } else {
  
          getServReq(RServiceEnd, RServicesValue,myId);
        }

        // if (masterAbout.length === 0) {
        //     const MasterID = AllServR[0].MasterID
        //     console.log(AllServR)
        //     getMaster(MasterID);
        //   }
      }
    }, [ServItemsR, RServicesValue, RServiceEnd, myId]);
  
    const fetchNewItems = () => {
      dispatch(onScrollCatalog(''));
      if (RServicesValue === '' && onShopId === '' && onCategoryId === '') {
        dispatch(AddCountItemAllServR(ServItemsR + 2));
      } else {
        dispatch(
            onSearchItemAllServRequests({
            RServicesValue: RServicesValue,
            RServiceEnd: RServiceEnd + 2
          }),
        );
      }
    };

    // console.log(AllServR)
  
    return (
      
  <><div
            className={s.all_items}
            id="scrollableDiv"
            style={{ overflowY: 'scroll', overflowX: 'hidden' }}>

            {AllServR.length > 0 ? (
                <InfiniteScroll
                    style={{}}
                    dataLength={AllServR.length}
                    next={fetchNewItems}
                    hasMore={true}
                    loader={<div style={{ width: '50%' }}>
                        {/* {Array.from(Array(4), (_, i) => (
                              <LoadingBlock key={uniqid()} />
                            ))} */}
                    </div>}
                    sscrollableTarget="scrollableDiv">
                    {AllServR.map((item) => (
                        <MyAcceptedRequestForClient WSCSend={WSCSend} key={uniqid()} {...item} />
                    ))}
                </InfiniteScroll>
            ) : (
              <><p style={{ marginBottom: '20px'}}>Ни один мастер пока что не принял вашу заявку &#9203;</p><ClientRequest /></>
                // Array.from(Array(4), (_) => <ServicesLoadingBlock key={uniqid()} />)
            )}
        </div><div className={s.footer}>

            </div></>
    );
  };

export default React.memo(MyAcceptedRequests);
