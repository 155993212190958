import React, { useEffect, useState } from 'react';
import s from './service.module.css';
import { onMastersCategory, onAddServForClientReq_1,
  PageDisplayType1,
  PageDisplayType2,
  PageDisplayType3,
  PageDisplayType4, } from '../../../redux/action';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';


const ServiceType = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // dispatch(onAddServForClientReq_1(''))
  // dispatch(PageDisplayType1('none'))
  // dispatch(PageDisplayType2('none'))
  // dispatch(PageDisplayType3('none'))
  // dispatch(PageDisplayType4('none'))

  const link = props.link;
  const img = props.img;
  const cat = props.cat;
  const mainCatID = props.mainCatID;

  // console.log('asdasdasd      ' + cat)
  let fullLink = link + '/' + cat;
  // console.log(name);
  // console.log(link);
  // console.log(cat);
  // console.log(fullLink);

  const toService = () => {
    dispatch(onMastersCategory(cat));
    localStorage.setItem('cat', props.cat_ru);
    localStorage.setItem('mainCatID', mainCatID);
    history.push(fullLink);
    // console.log(link);
  };

  return (
    <>
      { 
        // <Link to={props.link}>
          <div className={s.service_blok} onClick={toService}>
            <img src={img} />
            <p>{props.cat_ru}</p>
          </div>
        // </Link>
       }
    </>
  );
};

export default ServiceType;
