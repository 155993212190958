import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  osName,
  osVersion,
  mobileVendor,
  mobileModel,
  deviceDetect	
} from "react-device-detect";
import useReactIpLocation from "react-ip-details";
import SignIn from '../../../companents/SignIn/SignIn';
import { accountBeauty, onPBCmenu } from '../../../redux/action';
import s from '../pageMaster.module.css';
import no_avatar from '../style/no-avatar.png';
import MyRequests from '../../../companents/headerNew/MyRequests';
import MyAcceptedRequests from '../../../companents/headerNew/MyAcceptedRequests';
import beautyApi from '../../../api/beautyApi';

const ProfileBeautyClient = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [signIn, setSignIn] = useState(false);
  const login = JSON.parse(localStorage.getItem('account'));
  const accountBeautyInfo = useSelector((state) => state.accountBeauty);
  const [tab, setTab] = useState(0);
  const PBCmenu = useSelector((state) => state.PBCmenu);
  const PBCborderBottom = useSelector((state) => state.PBCborderBottom);
  const tabs = ['мои записи', 'история', 'избранное'];
  const time = localStorage.getItem('time');
  const {ipResponse} = useReactIpLocation({ numberToConvert: 100 });

  if (ipResponse && accountBeautyInfo.id && String(accountBeautyInfo.IMEI).length < 10) {
    const notificationdata = new FormData();
    notificationdata.append('ip', ipResponse.IPv4);
    notificationdata.append('id', accountBeautyInfo.id);
    notificationdata.append('time', time);
    notificationdata.append('osName', osName);
    notificationdata.append('osVersion', osVersion);
    notificationdata.append('mobileVendor', mobileVendor);
    notificationdata.append('mobileModel', mobileModel);
    beautyApi.userNotification(notificationdata)
    }

  useEffect(() => {
    if (login) {
      const regdata = new FormData();

      regdata.append('phone', login.login);
      regdata.append('pass', login.pass);
      regdata.append('status', 0);
      fetch(`https://bshop.kz/beauty/beautyApi/profil/logIn.php`, {
        method: 'POST',
        body: regdata,
      })
        .then(async (res) => await res.json())
        .then((res) => {
          setSignIn(!signIn);
          dispatch(accountBeauty(res[0]));
        });              
    }
  }, []);

  useEffect(() => {

  }, [PBCborderBottom]);

  const showR = (e) => {
    dispatch(onPBCmenu(e));
  };

  if (accountBeautyInfo.Email || login) {
    // статус 0 - клиент, 1 - мастер
    return (
      <>
        <div className={s.header}>
          <label htmlFor="file-input">
            <div className={s.avatar}>
              {accountBeautyInfo.img && accountBeautyInfo.img !== '' ? (
                <img
                  src={`https://bshop.kz/beauty/AvatarPhotos/${accountBeautyInfo.img}`}
                  alt="avatar"
                />
              ) : (
                <img src={no_avatar} alt="avatar" />
              )}
            </div>
          </label>

          <div className={s.info}>
            <div className={s.name_person}>{accountBeautyInfo.FIO}</div>
            <div className={s.conf}>
              <button
                className={s.buttonBeauty}
                onClick={(e) => history.push('/beauty/client/edit')}>
                Редактировать
              </button>
            </div>
          </div>
        </div>

        <div className={s.container}>
          <div className={s.client_name_tabs}>

          {tabs.map((item, key) => (
              <div key={key} onClick={() => {setTab(key); showR(item)} } className={key === tab ? s.active_tab : s.nonactive_tab}>{item}</div>
              ))} 

            {/* <ul className={s.uv}>
              <li
                style={{
                  borderBottom: `${PBCborderBottom}`,
                  borderImage: `${PBCborderImage}`,
                  color: `${PBCcolor}`,
                  fontWeight: `${PBCfontWeight}`,
                }}
                onClick={() => showR('req')}>
                <a>Мои записи</a>
              </li>
              <li onClick={() => showR('история')}>
                <a>история</a>
              </li>
              <li onClick={() => showR('Избранное')}>
                <a>Избранное</a>
              </li>
            </ul> */}

          </div>
        </div>

        {PBCmenu === 'мои записи' ? (
          <div className={s.myRequests}>
            <MyAcceptedRequests img={accountBeautyInfo.img} />
          </div>
        ) : (
          null
        )}

        {PBCmenu === 'история' ? (
          <div className={s.myRequests}>
            <MyRequests img={accountBeautyInfo.img} />
          </div>
        ) : (
          null
        )}
        
      </>
    );
  } else {
    return (
      <div>
        <SignIn />
      </div>
    );
  }
};

export default ProfileBeautyClient;
