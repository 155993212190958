import React, { useEffect, useRef, useState } from 'react';
import s from './header.module.css';
import '../bshop/css/index.css';
import { useHistory } from 'react-router-dom';
import {
  onShopGo,
  onSearchItemBshop,
  onItemGo,
  onScrollCatalog,
  accountBeauty,
  onServMastersMenu
} from '../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import ClientRequest from './ClientRequest';
import MasterRequest from './MasterRequest';
import AppStore from '../../asseets/app-store 1.png';
import GooglePlay from '../../asseets/google-play 1.png';
const HeaderBshop = ({ title, children, type }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const goShop = useSelector((state) => state.toShop);
  const goItem = useSelector((state) => state.toItem);
  const status = useSelector((state) => state.accountBeauty.status);
  const login = JSON.parse(localStorage.getItem('account'));
  const [signIn, setSignIn] = useState(false);

  // console.log(status);

  useEffect(() => {
    if (login) {
      const regdata = new FormData();

      regdata.append('phone', login.login);
      regdata.append('pass', login.pass);
      regdata.append('status', 0);
      fetch(`https://bshop.kz/beauty/beautyApi/profil/logIn.php`, {
        method: 'POST',
        body: regdata,
      })
        .then(async (res) => await res.json())
        .then((res) => {
          setSignIn(!signIn);
          dispatch(accountBeauty(res[0]));
        });
    }

    dispatch(onServMastersMenu('Запись'))

  }, []);

  const account = useSelector((state) => state.accountBeauty);

  if (type === 'bshop') {
    const goBackfromShop = () => {
      if (goShop && !goItem) {
        dispatch(onShopGo(false));
        dispatch(
          onSearchItemBshop({
            value: '',
            end: 20,
            onShopId: '',
            onCategoryId: '',
          }),
        );
        dispatch(onScrollCatalog(''));
        history.goBack();
      }

      if (!goShop && goItem) {
        history.goBack();
        dispatch(onItemGo(false));
      }

      if (!goShop && !goItem) {
        history.goBack();
      }
      if (goShop && goItem) {
        history.goBack();
        dispatch(onItemGo(false));
      }
    };

    return (
      <div className={s.root}>
        <div className={s.arrowBack} onClick={() => goBackfromShop()}>
          <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.505025 5.50503C0.231658 5.77839 0.231658 6.22161 0.505025 6.49497L4.9598 10.9497C5.23316 11.2231 5.67638 11.2231 5.94975 10.9497C6.22311 10.6764 6.22311 10.2332 5.94975 9.9598L1.98995 6L5.94975 2.0402C6.22311 1.76684 6.22311 1.32362 5.94975 1.05025C5.67638 0.776886 5.23316 0.776886 4.9598 1.05025L0.505025 5.50503ZM13 5.3L1 5.3V6.7L13 6.7V5.3Z"
              fill="#404246"
            />
          </svg>
        </div>
        <div className={s.title}>{title}</div>
        {children}
      </div>
    );
  }
  if (type === 'beauty') {
    const goBackfromShop = () => {
      history.goBack();
    };

    const goRequests = () => {
      history.push('/beauty/requests');
    };

    return (
      <div className={s.root_beauty}>
        <div className={s.arrowBack} onClick={() => goBackfromShop()}>
          <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.505025 5.50503C0.231658 5.77839 0.231658 6.22161 0.505025 6.49497L4.9598 10.9497C5.23316 11.2231 5.67638 11.2231 5.94975 10.9497C6.22311 10.6764 6.22311 10.2332 5.94975 9.9598L1.98995 6L5.94975 2.0402C6.22311 1.76684 6.22311 1.32362 5.94975 1.05025C5.67638 0.776886 5.23316 0.776886 4.9598 1.05025L0.505025 5.50503ZM13 5.3L1 5.3V6.7L13 6.7V5.3Z"
              fill="#404246"
            />
          </svg>
        </div>
        <div className={s.title_beuaty}>{title}</div>

        <ClientRequest />

        <div style = {{opacity: '0.2'}} className={s.celendar_beauty}>
          <svg
            style = {{marginRight: '10px'}}
            width="31"
            height="28"
            viewBox="0 0 31 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
              x="1.59697"
              y="2.7"
              width="28.6"
              height="24.6"
              rx="1.3"
              stroke="#404246"
              strokeWidth="1.4"
              strokeMiterlimit="3.8637"
            />
            <path d="M1.89697 7H21.897" stroke="#404246" strokeLinecap="round" />
            <path d="M29.897 7L27.897 7" stroke="#404246" strokeLinecap="round" />
            <path d="M23.897 7H25.897" stroke="#404246" strokeLinecap="round" />
            <path d="M4.89697 11H6.29697" stroke="#404246" strokeLinecap="round" />
            <path d="M8.29688 11H9.69687" stroke="#404246" strokeLinecap="round" />
            <path d="M11.697 11H13.097" stroke="#404246" strokeLinecap="round" />
            <path d="M15.0969 11H16.4969" stroke="#404246" strokeLinecap="round" />
            <path d="M18.4971 11H19.8971" stroke="#404246" strokeLinecap="round" />
            <path d="M21.897 11H23.297" stroke="#404246" strokeLinecap="round" />
            <path d="M25.2969 11H26.6969" stroke="#404246" strokeLinecap="round" />
            <path d="M4.89697 14H6.29697" stroke="#404246" strokeLinecap="round" />
            <path d="M8.29688 14H9.69687" stroke="#404246" strokeLinecap="round" />
            <path d="M11.697 14H13.097" stroke="#404246" strokeLinecap="round" />
            <path d="M15.0969 14H16.4969" stroke="#404246" strokeLinecap="round" />
            <path d="M18.4971 14H19.8971" stroke="#404246" strokeLinecap="round" />
            <path d="M21.897 14H23.297" stroke="#404246" strokeLinecap="round" />
            <path d="M25.2969 14H26.6969" stroke="#404246" strokeLinecap="round" />
            <path d="M4.89697 17H6.29697" stroke="#404246" strokeLinecap="round" />
            <path d="M8.29688 17H9.69687" stroke="#404246" strokeLinecap="round" />
            <path d="M11.697 17H13.097" stroke="#404246" strokeLinecap="round" />
            <path d="M15.0969 17H16.4969" stroke="#404246" strokeLinecap="round" />
            <path d="M18.4971 17H19.8971" stroke="#404246" strokeLinecap="round" />
            <path d="M21.897 17H23.297" stroke="#404246" strokeLinecap="round" />
            <path d="M25.2969 17H26.6969" stroke="#404246" strokeLinecap="round" />
            <path d="M4.89697 20H6.29697" stroke="#404246" strokeLinecap="round" />
            <path d="M8.29688 20H9.69687" stroke="#404246" strokeLinecap="round" />
            <path d="M11.697 20H13.097" stroke="#404246" strokeLinecap="round" />
            <path d="M15.0969 20H16.4969" stroke="#404246" strokeLinecap="round" />
            <path d="M18.4971 20H19.8971" stroke="#404246" strokeLinecap="round" />
            <path d="M21.897 20H23.297" stroke="#404246" strokeLinecap="round" />
            <path d="M25.2969 20H26.6969" stroke="#404246" strokeLinecap="round" />
            <path d="M4.89697 23H6.29697" stroke="#404246" strokeLinecap="round" />
            <path d="M8.29688 23H9.69687" stroke="#404246" strokeLinecap="round" />
            <path d="M11.697 23H13.097" stroke="#404246" strokeLinecap="round" />
            <path d="M4.89697 23H6.29697" stroke="#404246" strokeLinecap="round" />
            <path d="M8.29688 23H9.69687" stroke="#404246" strokeLinecap="round" />
            <path d="M11.697 23H13.097" stroke="#404246" strokeLinecap="round" />
            <rect x="6.39697" y="0.5" width="2" height="4" rx="1" fill="#F3F3F3" stroke="#404246" />
            <rect x="23.397" y="0.5" width="2" height="4" rx="1" fill="#F3F3F3" stroke="#404246" />
          </svg>
        </div>
        <div style = {{opacity: '0.3'}} className={s.bell_beuaty}>{children}</div>
      </div>
    );
  }
  if (type === 'preview') {
    const goBackfromShop = () => {
      history.goBack();
    };

    const goRequests = () => {
      history.push('/beauty/requests');
    };

    return (
      <div className={s.root_beauty_preview}>
        {/* <div className={s.title_beuaty}></div> */}

        <a style={{textDecoration: `none`, paddingTop: '7px', textAlign: 'center', margin: 'auto'}} className={s.beauty_button_confirm} href='https://admin.bikli.kz/authfromweb'>Авторизация</a>

        <div className={s.bell_beuaty}>{children}</div>
      </div>
    );
  }
  if (type === 'Login') {
    const goBackfromShop = () => {
      history.goBack();
    };

    const goRequests = () => {
      history.push('/beauty/requests');
    };

    return (
      // <div className={s.preview}>
      //   <a style = {{paddingTop: '8px', textDecoration: `none`, marginLeft: '0px', marginRight: '20px', position: 'relative', bottom: '16px', color: 'floralwhite' }}>Скачать приложение</a>

      //   <a href='https://apps.apple.com/us/app/partner-bikli/id1638081565'>
      //     <img style = {{marginTop: '8px', display: 'inline', marginLeft: '30px'}} src={AppStore}/>
      //   </a>
      //   <a href='https://play.google.com/store/apps/details?id=kz.bikli.mobile'>
      //     <img style = {{display: 'inline', marginLeft: '48px'}} src={GooglePlay} />
      //   </a>
      // </div>
      null
    );
  }
};

export default HeaderBshop;
