import React, { Component } from 'react';

import { Route, Switch } from 'react-router-dom';
import qs from 'qs';
import { connect } from 'react-redux';
import {
  setDate,
  filterCategory,
  errorStatus,
  countItem,
  onEmptyBasket,
  getUserData,
  delAdressDeliveryBasket,
} from './redux/action';

import Header from './companents/header';

import s from './companents/bshop/MainBshop.module.css';
import './companents/bshop/css/app.module.css';
import Footer from './companents/footer/Footer';

import ChoosePage from './pages/choosePage/choosePage';
import Main from './pages/main.js';
import Backet from './pages/shoping-backet.js';
import Gifts from './pages/gifts.js';
import History from './pages/history';
import Registration from './pages/reg';
import Login from './pages/auth';
import Cafe from './pages/cafe.js';
import Error from './pages/error';
import Recorcds from './pages/recorcds';
import Profile from './pages/profile';

import './App.css';
import HeaderBshop from './companents/headerNew/Header';
import MainBshop from './pages/bshop';
import OneItem from './companents/bshop/itemCatalogBshop/oneItem/OneItem';
import CartNew from './pages/cartNew/CartNew';
import Favorites from './pages/favorites';
import PageShop from './pages/pageShop/PageShop';
import Policy from './pages/policy/Policy';
import PageMaster from './pages/beauty/PageMaster';

import Registration_form from './pages/beauty/Registration_form';
import ServiceMenu from './companents/beauty/Service/ServiceMenu';
import ServiceMastersMenu from './companents/beauty/ServiceMasters/ServiceMastersMenu';
import ProfileBeauty from './pages/beauty/ProfileBeauty';
import MasterRequest from './companents/headerNew/MasterRequest';
import TypeRegistration from './pages/beauty/TypeRegistration';
import Registartion_form_client from './pages/beauty/Registartion_form_client';
import ProfileClientEdit from './pages/beauty/ProfileBeautyClient/ProfileClientEdit';

class App extends Component {
  state = {
    onLine: true,

    value: '',

    error: false,

    phone_user: null,
  };

  //Автовход
  async componentDidMount() {
    try {
      if (localStorage.getItem('access_token')) {
        const formData = await new FormData();
        await formData.append('access_token', localStorage.getItem('access_token'));
        const res = await fetch('https://bikli.kz/apiBikli/index.php?method=user.get.data', {
          method: 'POST',
          body: formData,
        });
        const json = await res.json();
        await this.props.getUserData(json);
      }
    } catch (error) {}
  }

  //Получение данных , с разных страниц
  getData = async (item, url, data = null) => {
    try {
      const res = data
        ? await fetch(`https://bikli.kz/apiBikli/index.php?method=${url}`, {
            method: 'POST',
            body: data,
          })
        : await fetch(`https://bikli.kz/apiBikli/index.php?method=${url}`);
      const json = await res.json();
      await this.props.setDate(json, item);
    } catch (error) {
      await this.props.errorStatus();
      console.error(error);
    }
  };

  filterCategoryItem = (id) => {
    this.props.filterCategory(id);
  };

  searchFilterInput = (e) => {
    this.setState({ value: e.target.value });
  };

  countItem = (id, operator, item) => {
    this.props.countItem(id, operator, item);
  };

  //Очистка корзины
  onEmptyBasket = () => {
    this.props.onEmptyBasket();
  };

  //Заказ товара
  orderDelivery = async (deliveryInfo) => {
    const groups = await this.props.basket.reduce((groups, item) => {
      groups[item.idcafe] = groups[item.idcafe] || [];
      groups[item.idcafe].push(item);
      return groups;
    }, {});
    const resGroup = await Object.values(groups);

    try {
      await resGroup.forEach((el) => {
        //доставка
        const { name, phone, id } = Object.assign({}, ...this.props.user);
        const data = {
          order: el.map((el) => el.id).join(', ') + ',',
          count: el.map((el) => el.count).join(', ') + ',',
          amount: el.reduce((acc, el) => acc + el.amount, 0),
          cafe_id: el
            .map((el) => el.idcafe)
            .slice(0, 1)
            .toString(),
          phone: phone,
          device_id: '89728100-5d91-11ec-a59f-626791ef1f46',
          user_id: id,
          name: name,
        };
        const orderRes = { ...deliveryInfo, ...data };
        const formData = new FormData();
        for (const key in orderRes) {
          formData.append(`${key}`, orderRes[key]);
        }
        fetch('https://bikli.kz/apiBikli/index.php?method=sell.PostOrder', {
          method: 'POST',
          body: formData,
        });

        //запрос на добавления Адреса
        const { user_id, adress, apartment, street, floor, entrance } = orderRes;
        const dataForAdress = { user_id, adress, apartment, street, floor, entrance };
        const formDataAdress = new FormData();
        for (const key in dataForAdress) {
          formDataAdress.append(`${key}`, dataForAdress[key]);
        }
        fetch('https://bikli.kz/apiBikli/index.php?method=user.adress.add', {
          method: 'POST',
          body: formDataAdress,
        });
        // const json = res.json();
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Регистрация
  regNewUser = async ({ name_user, phone_user }) => {
    this.setState({ phone_user });
    const formData = await new FormData();
    const newUser = { name_user, phone_user };
    for (const key in newUser) {
      await formData.append(`${key}`, newUser[key]);
    }
    const res = await fetch('https://bikli.kz/apiBikli/index.php?method=user.send.sms', {
      method: 'POST',
      body: formData,
    });
    await res.json();
  };

  //Авторизация в приложении
  authUser = async (authUser) => {
    const formData = await new FormData();
    for (const key in authUser) {
      await formData.append(`${key}`, authUser[key]);
    }
    await formData.append(`phone_user`, this.state.phone_user);

    const res = await fetch('https://bikli.kz/apiBikli/index.php?method=user.login', {
      method: 'POST',
      body: formData,
    });
    const json = await res.json();
    const { token, check } = await json.response;
    if (check) {
      await this.setState({ error: false });
      await localStorage.setItem('access_token', token);
    } else {
      await this.setState({ error: true });
    }
    this.setState({ phone_user: '' });
  };

  //Повтроная отправка СМС
  sendSMS = async (phone) => {
    const formData = await new FormData();
    await formData.append('phone_user', phone);
    await fetch('https://bikli.kz/apiBikli/index.php?method=user.send.sms', {
      method: 'POST',
      body: formData,
    });
  };

  //Вход в АРР и получение данных о пользователе
  getDataUser = async () => {
    const formData = await new FormData();
    await formData.append('access_token', localStorage.getItem('access_token'));
    const res = await fetch('https://bikli.kz/apiBikli/index.php?method=user.get.data', {
      method: 'POST',
      body: formData,
    });
    const json = await res.json();
    await this.props.getUserData(json);
  };

  //Добавление нового адреса пользоватля
  addNewAdress = async (data) => {
    const formData = await new FormData();
    for (const key in data) {
      await formData.append(`${key}`, data[key]);
    }
    await formData.append('user_id', Object.assign(...this.props.user, {}).id);
    const res = await fetch('https://bikli.kz/apiBikli/index.php?method=user.adress.add', {
      method: 'POST',
      body: formData,
    });
    await res.json();
  };

  //удаление адреса клиента из корзины
  delAdressDeliveryBasket = async (id) => {
    const formData = await new FormData();
    await formData.append('id', id);
    await fetch('https://bikli.kz/apiBikli/index.php?method=user.adress.delete', {
      method: 'POST',
      body: formData,
    });
    await this.props.delAdressDeliveryBasket(id);
  };
  render() {
    const newData = this.props.data.filter(
      (el) => el.name.toLowerCase().indexOf(this.state.value.toLowerCase()) !== -1,
    );

    const body = document.querySelector('body');
    if (!this.props.bodyOverflowToCard) {
      body.classList.add('overflow');
    } else {
      body.classList.remove('overflow');
    }

    const urlPath = window.location.pathname;
    localStorage.setItem('urlPath', urlPath);

    let authBeauty = localStorage.getItem('account');
    return (
      <div className="app">
        {this.props.error ? (
          <Error />
        ) : (
          <>
            <Switch>
              <Route path="/" render={({ match }) => <ChoosePage match={match} />} exact />

              <>
                {urlPath === '/shopper/item' ||
                qs.parse(urlPath.substring(9)).shopid > 0 ||
                urlPath === '/shopper' ||
                urlPath === '/cart' ||
                urlPath === '/gift' ||
                urlPath === '/policy' ||
                urlPath === '/favorites' ? (
                  <HeaderBshop title={'Bshop'} type="bshop">
                    <div className={s.notification}>
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M20.7604 17.8673C20.0809 17.2778 19.4859 16.602 18.9916 15.858C18.452 14.8312 18.1285 13.7097 18.0403 12.5596V9.17195C18.0449 7.36541 17.3715 5.61939 16.1466 4.26194C14.9216 2.90449 13.2294 2.02905 11.3879 1.8001V0.915481C11.3879 0.67268 11.2888 0.439824 11.1124 0.268138C10.9359 0.0964523 10.6967 0 10.4471 0C10.1976 0 9.95835 0.0964523 9.78192 0.268138C9.60549 0.439824 9.50638 0.67268 9.50638 0.915481V1.81382C7.68139 2.05927 6.00964 2.94 4.80078 4.29289C3.59191 5.64578 2.92783 7.37915 2.93154 9.17195V12.5596C2.84328 13.7097 2.51984 14.8312 1.9802 15.858C1.49458 16.6003 0.909198 17.276 0.239597 17.8673C0.164428 17.9316 0.104184 18.0107 0.0628713 18.0994C0.0215592 18.188 0.000126702 18.2843 0 18.3816V19.3142C0 19.4961 0.0742447 19.6705 0.206401 19.7991C0.338558 19.9278 0.517801 20 0.704698 20H20.2953C20.4822 20 20.6614 19.9278 20.7936 19.7991C20.9258 19.6705 21 19.4961 21 19.3142V18.3816C20.9999 18.2843 20.9784 18.188 20.9371 18.0994C20.8958 18.0107 20.8356 17.9316 20.7604 17.8673ZM1.46577 18.6285C2.12143 18.0121 2.69871 17.3214 3.18524 16.5712C3.865 15.331 4.26161 13.9626 4.34799 12.5596V9.17195C4.32004 8.36827 4.45857 7.56734 4.75533 6.81685C5.0521 6.06635 5.50102 5.38165 6.07538 4.8035C6.64973 4.22535 7.33777 3.76559 8.09852 3.45158C8.85927 3.13758 9.67717 2.97576 10.5035 2.97576C11.3299 2.97576 12.1478 3.13758 12.9085 3.45158C13.6693 3.76559 14.3573 4.22535 14.9317 4.8035C15.506 5.38165 15.955 6.06635 16.2517 6.81685C16.5485 7.56734 16.687 8.36827 16.6591 9.17195V12.5596C16.7454 13.9626 17.1421 15.331 17.8218 16.5712C18.3083 17.3214 18.8856 18.0121 19.5413 18.6285H1.46577Z"
                          fill="#A8A8A8"
                        />
                      </svg>
                    </div>
                  </HeaderBshop>
                ) : urlPath === '/beauty' ||
                  urlPath === '/beauty/master' ||
                  urlPath === '/beauty/masters/hair' ||
                  urlPath === '/beauty/masters/nails' ||
                  urlPath === '/beauty/masters/eyelashes' ||
                  urlPath === '/beauty/masters/brows' ||
                  urlPath === '/beauty/masters/legs' ||
                  urlPath === '/beauty/masters/lips' ||
                  urlPath === '/beauty/master' ||
                  urlPath === '/beauty/profile' && authBeauty ||
                  urlPath === '/beauty/client/edit' ||
                  urlPath === '/beauty/favorites' ? (
                  <HeaderBshop title={'Beauty'} type="beauty">
                    <div className={s.notification}>
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M20.7604 17.8673C20.0809 17.2778 19.4859 16.602 18.9916 15.858C18.452 14.8312 18.1285 13.7097 18.0403 12.5596V9.17195C18.0449 7.36541 17.3715 5.61939 16.1466 4.26194C14.9216 2.90449 13.2294 2.02905 11.3879 1.8001V0.915481C11.3879 0.67268 11.2888 0.439824 11.1124 0.268138C10.9359 0.0964523 10.6967 0 10.4471 0C10.1976 0 9.95835 0.0964523 9.78192 0.268138C9.60549 0.439824 9.50638 0.67268 9.50638 0.915481V1.81382C7.68139 2.05927 6.00964 2.94 4.80078 4.29289C3.59191 5.64578 2.92783 7.37915 2.93154 9.17195V12.5596C2.84328 13.7097 2.51984 14.8312 1.9802 15.858C1.49458 16.6003 0.909198 17.276 0.239597 17.8673C0.164428 17.9316 0.104184 18.0107 0.0628713 18.0994C0.0215592 18.188 0.000126702 18.2843 0 18.3816V19.3142C0 19.4961 0.0742447 19.6705 0.206401 19.7991C0.338558 19.9278 0.517801 20 0.704698 20H20.2953C20.4822 20 20.6614 19.9278 20.7936 19.7991C20.9258 19.6705 21 19.4961 21 19.3142V18.3816C20.9999 18.2843 20.9784 18.188 20.9371 18.0994C20.8958 18.0107 20.8356 17.9316 20.7604 17.8673ZM1.46577 18.6285C2.12143 18.0121 2.69871 17.3214 3.18524 16.5712C3.865 15.331 4.26161 13.9626 4.34799 12.5596V9.17195C4.32004 8.36827 4.45857 7.56734 4.75533 6.81685C5.0521 6.06635 5.50102 5.38165 6.07538 4.8035C6.64973 4.22535 7.33777 3.76559 8.09852 3.45158C8.85927 3.13758 9.67717 2.97576 10.5035 2.97576C11.3299 2.97576 12.1478 3.13758 12.9085 3.45158C13.6693 3.76559 14.3573 4.22535 14.9317 4.8035C15.506 5.38165 15.955 6.06635 16.2517 6.81685C16.5485 7.56734 16.687 8.36827 16.6591 9.17195V12.5596C16.7454 13.9626 17.1421 15.331 17.8218 16.5712C18.3083 17.3214 18.8856 18.0121 19.5413 18.6285H1.46577Z"
                          fill="#A8A8A8"
                        />
                      </svg>
                    </div>
                  </HeaderBshop>
                ) : urlPath === '/preview' ? (
              
                <HeaderBshop title={'Preview'} type="preview">
                </HeaderBshop>
              ) : urlPath === '/beauty/profile' && authBeauty === null ? (
              
                <HeaderBshop title={'Login'} type="Login">
                </HeaderBshop>
              ) : urlPath === '/beauty/registration' ||
                  urlPath === '/beauty/registration/beautymaster' ||
                  urlPath === '/beauty/registration/client' ? null : (
                  <Header backetCount={this.props.numItemsInBasket} />
                )}

                  <div className="app-inner">
                  <Switch>
                  
                    <Route
                      path="/main"
                      render={({ match }) => (
                        <Main
                          getData={this.getData}
                          searchFilterInput={this.searchFilterInput}
                          newData={newData}
                          data={this.props.data}
                          match={match}
                        />
                      )}
                      exact
                    />

                    <Route
                      path="/backet"
                      render={({ match }) => (
                        <Backet
                          countItem={this.countItem}
                          onEmptyBasket={this.onEmptyBasket}
                          orderDelivery={this.orderDelivery}
                          regNewUser={this.regNewUser}
                          errorModalOrder={this.state.errorModalOrder}
                          getData={this.getData}
                          delAdressDeliveryBasket={this.delAdressDeliveryBasket}
                          match={match}
                        />
                      )}
                      exact
                    />

                    <Route
                      path="/gifts"
                      render={({ match }) => <Gifts getData={this.getData} match={match} />}
                      exact
                    />

                    <Route
                      path="/records"
                      render={({ match }) => <Recorcds match={match} />}
                      exact
                    />

                    <Route
                      path="/history"
                      render={({ match }) => <History getData={this.getData} match={match} />}
                      exact
                    />

                    <Route
                      path="/profile"
                      render={({ match }) => (
                        <Profile addNewAdress={this.addNewAdress} match={match} />
                      )}
                      exact
                    />

                    <Route
                      path="/reg"
                      render={({ match }) => (
                        <Registration regNewUser={this.regNewUser} match={match} />
                      )}
                      exact
                    />

                    <Route
                      path="/auth/:phone?"
                      render={({ match }) => (
                        <Login
                          authUser={this.authUser}
                          sendSMS={this.sendSMS}
                          getDataUser={this.getDataUser}
                          error={this.state.error}
                          phone_user={this.state.phone_user}
                          match={match}
                        />
                      )}
                      exact
                    />

                    <Route
                      path="/cafe/:id?"
                      render={({ match }) => (
                        <Cafe
                          getData={this.getData}
                          filterCategoryItem={this.filterCategoryItem}
                          match={match}
                        />
                      )}
                      exact
                    />

                    <Route
                      path="/shopper"
                      render={({ match }) => (
                        <MainBshop
                          getData={this.getData}
                          filterCategoryItem={this.filterCategoryItem}
                          match={match}
                        />
                      )}
                      exact
                    />

                    <Route path="/shopper/item" render={({ match }) => <OneItem />} exact />

                    <Route path="/cart" render={({ match }) => <CartNew />} exact />

                    <Route path="/favorites" render={({ match }) => <Favorites />} exact />

                    <Route path="/gift" render={({ match }) => <Favorites />} exact />

                    <Route path="/shopper/:shopid?" render={({ match }) => <PageShop />} exact />

                    <Route path="/policy" render={({ match }) => <Policy />} exact />
                    </Switch>
                    </div>
                    <div className="app-inner-beauty">
                    <Switch>
                    <Route
                      path="/beauty/masters/hair"
                      render={({ match }) => (
                        <ServiceMastersMenu getData={this.getData} match={match} />
                      )}
                      exact
                    />

                    <Route
                      path="/beauty/registration/beautymaster"
                      render={({ match }) => (
                        <Registration_form getData={this.getData} match={match} />
                      )}
                      exact
                    />

                    <Route
                      path="/beauty/masters/lips"
                      render={({ match }) => (
                        <ServiceMastersMenu getData={this.getData} match={match} />
                      )}
                      exact
                    />

                    <Route
                      path="/beauty/masters/nails"
                      render={({ match }) => (
                        <ServiceMastersMenu getData={this.getData} match={match} />
                      )}
                      exact
                    />

                    <Route
                      path="/beauty/masters/eyelashes"
                      render={({ match }) => (
                        <ServiceMastersMenu getData={this.getData} match={match} />
                      )}
                      exact
                    />

                    <Route
                      path="/beauty/masters/brows"
                      render={({ match }) => (
                        <ServiceMastersMenu getData={this.getData} match={match} />
                      )}
                      exact
                    />

                    <Route
                      path="/beauty/masters/legs"
                      render={({ match }) => (
                        <ServiceMastersMenu getData={this.getData} match={match} />
                      )}
                      exact
                    />

                    <Route
                      path="/beauty/favorites"
                      render={({ match }) => (
                        <Favorites />
                        )}
                        exact
                      />

                    <Route
                      path="/preview"
                      render={({ match }) => <MasterRequest getData={this.getData} match={match} />}
                      exact
                    />

                    <Route
                      path="/beauty/registration"
                      render={({ match }) => (
                        <TypeRegistration getData={this.getData} match={match} />
                      )}
                      exact
                    />

                    <Route
                      path="/beauty/registration/client"
                      render={({ match }) => (
                        <Registartion_form_client getData={this.getData} match={match} />
                      )}
                      exact
                    />



                    <Route path="/beauty" render={({ match }) => <ServiceMenu />} exact />

                    <Route
                      path="/beauty/master/:masterid?"
                      render={({ match }) => <PageMaster />}
                      exact
                    />
                    <Route path="/beauty/client/edit" render={() => <ProfileClientEdit />} exact />

                    <Route
                      path="/beauty/requests"
                      render={({ match }) => <MasterRequest getData={this.getData} match={match} />}
                      exact
                    />
                    <Route path="/beauty/profile" render={({ match }) => <ProfileBeauty />} exact />

                    
                  </Switch>
                  </div>
                {urlPath === '/shopper' ||
                urlPath === '/shopper/item' ||
                urlPath === '/cart' ||
                urlPath === '/favorites' ||
                qs.parse(urlPath.substring(9)).shopid > 0 ? (
                  <Footer type="bshop" />
                ) : null}

                {urlPath === '/beauty/master' ||
                urlPath === '/beauty' ||
                urlPath === '/beauty/masters/hair' ||
                urlPath === '/beauty/masters/nails' ||
                urlPath === '/beauty/masters/eyelashes' ||
                urlPath === '/beauty/masters/brows' ||
                urlPath === '/beauty/masters/legs' ||
                urlPath === '/beauty/masters/lips' ||
                urlPath === '/beauty/profile' ||
                urlPath === '/beauty/registration' ||
                urlPath === '/beauty/registration/beautymaster' ||
                urlPath === '/beauty/registration/client' ||
                urlPath === '/beauty/client/edit' ||
                urlPath === '/beauty/favorites' ? (
                  <Footer type="beauty" />
                ) : null}

                {urlPath === '/preview' ? (
                  <Footer type="preview" />
                ) : null}
              </>
            </Switch>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = {
  setDate,
  filterCategory,
  errorStatus,
  countItem,
  onEmptyBasket,
  getUserData,
  delAdressDeliveryBasket,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
