import React, {useState } from 'react';
import s from './ServiceMasters.module.css';
import 'react-calendar/dist/Calendar.css';
import MastersWrapper from './MastersCatalog/MastersWrapper';
import ServicesWrapper from './ServicesCatalog/ServicesWrapper';
import RequestCatalog from './RequestCatalog/RequestCatalog';
import { useSelector, useDispatch } from 'react-redux';
import { onServMastersMenu } from '../../../redux/action';

const ServicesOrMasters = (props) => {
    const dispatch = useDispatch();
    const ServMastersMenu = useSelector((state) => state.ServMastersMenu);
    const [tab, setTab] = useState(2);
    const tabs = ['Услуги', 'Мастера', 'Запись'];
    const showR = (e) => {
        dispatch(onServMastersMenu(e));
      };
  
  return (
            <>
            <div className={s.choice_of_services_and_masters}>

                <div className={s.client_name_tabs}>

                {tabs.map((item, key) => (
                    <div key={key} onClick={() => {setTab(key); showR(item)} } className={key === tab ? s.active_tab : undefined}>{item}</div>
                    ))} 

                </div>
                {/* <div className={s.choice_of_services} 
                style={{
                    borderBottom: `${choice_of_services_Sborder}`, 
                    borderImageSource: `${choice_of_services_SborderImg}`,
                    borderImageSlice: 1
                }} 
                    onClick={() => ShowS(true)}>
                        <p>Услуги</p>
                </div>

                <div className={s.choice_of_masters} 
                style={{
                    borderBottom: `${choice_of_services_Mborder}`,
                    borderImageSource: `${choice_of_services_MborderImg}`,
                    borderImageSlice: 1
                }}  
                onClick={() => ShowM(true)}>
                    <p>Мастера</p>
                </div>

                <div className={s.choice_of_masters} 
                style={{
                    borderBottom: `${choice_of_services_Rborder}`,
                    borderImageSource: `${choice_of_services_RborderImg}`,
                    borderImageSlice: 1
                }}  
                onClick={() => ShowR(true)}>
                    <p>Запись</p>
                </div> */}

            </div>

            {/* {!ShowHideMasters & ShowHideReq  && <RequestCatalog />}
            {ShowHideMasters && <MastersWrapper />}
            {!ShowHideMasters && <ServicesWrapper />} */}

            {/* {
                ShowHideReq ? <RequestCatalog /> :
                ShowHideMasters ? <MastersWrapper /> :
                ShowHideServs ? <ServicesWrapper /> :
                console.log('')
            } */}

            {ServMastersMenu === 'Запись' ? (
                <RequestCatalog />
            ) : (
            null
            )}

            {ServMastersMenu === 'Мастера' ? (
                <MastersWrapper />
            ) : (
            null
            )}

            {ServMastersMenu === 'Услуги' ? (
                <ServicesWrapper />
            ) : (
            null
            )}
            
            </>
          
)
       
  

};

export default ServicesOrMasters;
