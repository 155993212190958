import React, { Component } from 'react';

import { connect } from 'react-redux';
import { chooseTypeSection } from '../../redux/action';

import FormAddPartner from './formAddPartner/index.js';

import chooseFood from '../../asseets/choose-food.png';
import chooseFlower from '../../asseets/choose-flower.png';
import chooseGift from '../../asseets/choose-gift.png';
import chooseHelp from '../../asseets/choose-help.png';
import chooseShopper from '../../asseets/choose-shopper.png';
import chooseBeauty from '../../asseets/Beauty.png';

import './style.css';
import Button from '../../companents/button';
import { Link } from 'react-router-dom';

const time = localStorage.getItem('time');
var now = String(new Date().getTime())
time ? console.info() : localStorage.setItem('time', now.substr(0,10));

class choosePage extends Component {
  state = {
    showPopUpPartner: false,

    showFormPartner: false,
  };

  showPopUpPartner = () => {
    this.setState({ showPopUpPartner: !this.state.showPopUpPartner });
  };

  toggleFormPartner = () =>
    this.setState({ showFormPartner: !this.state.showFormPartner, showPopUpPartner: false });

  chooseTypeSection = (el) => {
    this.props.chooseTypeSection(el.type);
  };

  render() {
    const chooseItems = [
      { title: 'Доставка еды', img: chooseFood, id: 1, type: 'main' },
      { title: 'Bshop', img: chooseShopper, id: 2, type: 'shopper' },
      { title: 'Beauty', img: chooseBeauty, id: 3, type: 'beauty' },
      { title: 'Поздравления', img: chooseGift, id: 4, type: 'gift' },
    ];

    return (
      <>
        {this.state.showFormPartner ? (
          <FormAddPartner
            showFormPartner={this.state.showFormPartner}
            toggleFormPartner={this.toggleFormPartner}
          />
        ) : (
          <div className="choose-page">
            <div className="choose-wrapper">
              <h1 className="choose-page-title">Закажи на Bikli</h1>

              <div className="choose-items">
                {chooseItems.map((el) => {
                  return (
                    <Link to={`/${el.type}`} key={el.id}>
                      <div className="choose-item-main" onClick={() => this.chooseTypeSection(el)}>
                        <div className="wrapper-img-choose-page">
                          {' '}
                          <img src={el.img} alt="" />
                        </div>
                        <h5>{el.title}</h5>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>

            <Button
              value="Информация для партнеров"
              class="btn_info_for_partners"
              click={this.showPopUpPartner}
            />

            {this.state.showPopUpPartner ? (
              <div className="popup-info-partner">
                <Button
                  value="Закрыть"
                  class="btn_closePopUpPartner"
                  click={this.showPopUpPartner}
                />

                <div className="popup-info-partner-text">
                  <p>*Хотите разместить свой товар/продукт на Bikli?</p>
                  <p>Оставьте заявку и мы добавим его в список категорий</p>
                  <p>*Не можете найти категорию для вашего продукта?</p>
                  <p>
                    Оставьте заявку и возможно именно ваша ниша создаст новую категорию на Bikli.
                  </p>
                </div>

                <Button
                  value="СТАТЬ ПАРТНЕРОМ"
                  class="btn_stayPartner"
                  click={this.toggleFormPartner}
                />
              </div>
            ) : null}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { chooseTypeSection };

export default connect(mapStateToProps, mapDispatchToProps)(choosePage);
